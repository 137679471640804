import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Typography,
  Paper,
  FormControlLabel,
  Switch,
  CircularProgress,
  Tabs,
  Tab,
  AppBar,
  Card,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogActions,
  Grid,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import firebase from "../../config/firebase";
import { lessonData } from "../../config/lessons";
import { AuthContext } from "../../providers/Auth";

import Reminder from "./Reminder";
import { DateTime } from "luxon";
import { useSwipeable } from "react-swipeable";
import SubscriptionOptions from "../Settings/SubscriptionOptions";
import Dashboard from "../Settings/Dashboard";
import PWAInstallation from "../appSupport/supportSnippets/PWAInstallation";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const DailyLesson = (props) => {
  const { classes, match, history } = props;
  const { params } = match;
  const { tab } = params;
  const { currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [lessonIndex, setLessonIndex] = useState(0);
  const [subscriptionStatus, setSubscriptionStatus] = useState("active");
  const [pwaAlert, setPwaAlert] = useState();

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      handleChange(eventData, 1);
    },
    onSwipedRight: (eventData) => {
      handleChange(eventData, 0);
    },
  });

  const db = firebase.firestore();

  useEffect(() => {
    let unsubscribe = null;

    if (currentUser !== null) {
      unsubscribe = db
        .collection(`users`)
        .doc(`${currentUser.uid}`)
        .onSnapshot((doc) => {
          setLessonIndex(doc.data().currentLesson - 1);
          setUserData(doc.data());
          // console.log(doc.data());
          if (doc.data().pwaAcknowledged !== undefined) {
            setPwaAlert(!doc.data().pwaAcknowledged);
          } else {
            setPwaAlert(true);
          }
          checkSubscriptionStatus(doc.data().subscription);
        });
    } else {
      props.history.replace("/login");
    }

    return () => unsubscribe();
  }, [currentUser, db, props.history]);

  const checkSubscriptionStatus = (userSubscriptionData) => {
    if (
      userSubscriptionData.status === "canceled" ||
      userSubscriptionData.status === "unpaid" ||
      userSubscriptionData.status === "incomplete_expired"
    ) {
      setSubscriptionStatus("canceled");
    }

    if (
      userSubscriptionData.status === "past_due" ||
      userSubscriptionData.status === "incomplete"
    ) {
      setSubscriptionStatus("incomplete");
    }

    if (userSubscriptionData.status === "trialing") {
      setSubscriptionStatus("trial");
    }
  };

  const tabNameToIndex = {
    0: "text",
    1: "exercise",
    2: "subscribe",
  };

  const indexToTabName = {
    text: 0,
    exercise: 1,
    subscribe: 2,
  };

  const [selectedTab, setSelectedTab] = useState(indexToTabName[tab]);

  const handleChange = (event, newValue) => {
    history.push(`/daily-lesson/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
  };

  const markCompleted = async (direction) => {
    let updateCompletedLessons = [...userData.completedLessons];
    if (direction === "Forward") {
      updateCompletedLessons.push(userData.currentLesson);
      const newReminderArray = newReminders("default");
      newReminderArray.sort();
      let newNextReminder = null;
      if (newReminderArray.length > 0) {
        newNextReminder = newReminderArray[0];
      }
      setSelectedTab(0);
      history.push("/daily-lesson/text");
      await db
        .collection(`users`)
        .doc(`${currentUser.uid}`)
        .update({
          // add completed lesson to completedLessons array
          completedLessons: updateCompletedLessons,
          // increment currentLesson
          currentLesson: userData.currentLesson + 1,
          // set upcoming_reminders
          "messaging.upcoming_reminders": newReminderArray,
          "messaging.next_reminder": newNextReminder,
        });
    } else if (direction === "Back") {
      updateCompletedLessons.filter((lessonNo) => {
        return lessonNo !== userData.currentLesson;
      });
      setSelectedTab(0);
      history.push("/daily-lesson/text");
      await db
        .collection(`users`)
        .doc(`${currentUser.uid}`)
        .update({
          // add completed lesson to completedLessons array
          completedLessons: updateCompletedLessons,
          // increment currentLesson
          currentLesson: userData.currentLesson - 1,
          // set upcoming_reminders
          "messaging.upcoming_reminders": [],
          "messaging.next_reminder": null,
        });
    } else {
      console.error("Error - Response not recoginised");
    }
  };

  const newReminders = (choice) => {
    let reminderArray = [];
    if (userData.messaging.default || choice === "user") {
      // Define number of millseconds to move forward one day
      let jumpForwardMilliSecs = 24 * 60 * 60 * 1000;

      // get daily and hourly reps for next lesson
      const nextIndex = lessonIndex + 1;
      let dailyReminderNumber = lessonData[nextIndex].dailyReps;
      let hourlyReminderNumber = lessonData[nextIndex].hourlyReps;

      // define the active period start assuming it was the current day.
      const activePeriodStart = new Date();
      activePeriodStart.setHours(userData.messaging.active_period.start);
      activePeriodStart.setMinutes(0);

      // define the active period end assuming it was the current day.
      const activePeriodEnd = new Date();
      activePeriodEnd.setHours(userData.messaging.active_period.end);
      activePeriodEnd.setMinutes(0);

      // check WHEN the user has marked complete. If marked complete within first 20% of active period (grace period), then set reminders for today by resetting jumpForwardMilliSecs
      const gracePeriodMilliSecs =
        (activePeriodEnd.getTime() - activePeriodStart.getTime()) * 0.2;
      console.log("Grace Hours: ", gracePeriodMilliSecs / 1000 / 60 / 60);
      const currentTime = new Date();
      console.log("Current Time: ", currentTime.getTime());
      const checkTime = activePeriodStart.getTime() + gracePeriodMilliSecs;
      console.log("Check Time: ", checkTime);
      if (currentTime.getTime() < checkTime) {
        jumpForwardMilliSecs = 0;
      }

      if (dailyReminderNumber > 0) {
        const periods = dailyReminderNumber === 1 ? 1 : dailyReminderNumber - 1;
        const reminderInterval =
          (activePeriodEnd - activePeriodStart) / periods;
        for (let i = 0; i < dailyReminderNumber; i++) {
          const newTime =
            activePeriodStart.getTime() +
            i * reminderInterval +
            jumpForwardMilliSecs;
          if (newTime > DateTime.local()) {
            reminderArray.push(newTime);
          }
        }
        return reminderArray;
      } else if (hourlyReminderNumber > 0) {
        const numberOfReminders =
          (userData.messaging.active_period.end -
            userData.messaging.active_period.start) *
          hourlyReminderNumber;
        const reminderInterval =
          (activePeriodEnd - activePeriodStart) / numberOfReminders;
        for (let i = 0; i < numberOfReminders; i++) {
          const newTime =
            activePeriodStart.getTime() +
            i * reminderInterval +
            jumpForwardMilliSecs;
          if (newTime > DateTime.local()) {
            reminderArray.push(newTime);
          }
        }
        return reminderArray;
      } else {
        return reminderArray;
      }
    } else {
      return reminderArray;
    }
  };

  const handleAlertClose = () => {
    setPwaAlert(false);
  };

  const pwaAlertHide = (event) => {
    db.collection("users").doc(`${currentUser.uid}`).update({
      pwaAcknowledged: event.target.checked,
    });
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const truncate = (text, length, useWordBoundary) => {
    if (text.length <= length) {
      return text;
    }
    const substring = text.substr(0, length - 1);
    return (
      (useWordBoundary
        ? substring.substr(0, substring.lastIndexOf(" "))
        : substring) + "&hellip;"
    );
  };

  const displayWarnings = () => {
    const dateNow = DateTime.local();
    const warningDate = dateNow.minus({ days: 3 });

    if (subscriptionStatus === "incomplete") {
      return (
        <Fragment>
          <Card className={classes.notifyCard}>
            <CardContent data-cy="notification-content">
              There was a problem with your payment method. To prevent
              disruptions to your access please go to the subscription dashboard
              to resolve the issue.
            </CardContent>
            <CardActions>
              <Dashboard userData={userData} />
            </CardActions>
          </Card>
        </Fragment>
      );
    }

    if (
      subscriptionStatus === "trial" &&
      userData.subscription.current_period_end >= warningDate
    ) {
      return (
        <Fragment>
          <Card className={classes.notifyCard}>
            <CardContent data-cy="notification-content">
              Your trial has almost finished... if you are enjoying Badass ACIM
              then please head over to the subscription tab to choose an option.
            </CardContent>
          </Card>
        </Fragment>
      );
    }
  };

  if (userData === null) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress
          size={200}
          thickness={1.5}
          className={classes.CircularProgress}
        />
      </div>
    );
  }

  if (subscriptionStatus === "canceled") {
    return (
      <>
        <main className={classes.main} {...handlers}>
          <Paper className={classes.paper}>
            <AppBar position="static">
              <Tabs
                value={selectedTab}
                centered={true}
                onChange={handleChange}
                aria-label="simple tabs lesson"
              >
                <Tab label="Text" {...a11yProps(0)} data-cy="tabHeader1" />
                <Tab label="Subscribe" {...a11yProps(1)} data-cy="tabHeader2" />
              </Tabs>
            </AppBar>
            {selectedTab === 0 && (
              <Fragment>
                <Typography variant="subtitle1" data-cy="lessonNo">
                  Lesson No. {userData.currentLesson}
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  dangerouslySetInnerHTML={{
                    __html: lessonData[lessonIndex].title.toUpperCase(),
                  }}
                />
                <Typography
                  variant="caption"
                  display="block"
                  dangerouslySetInnerHTML={{
                    __html: lessonData[lessonIndex].quoteText,
                  }}
                />
                <Typography
                  variant="caption"
                  className={classes.quoteAuthor}
                  gutterBottom
                  dangerouslySetInnerHTML={{
                    __html: lessonData[lessonIndex].quoteAuthor,
                  }}
                />
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: truncate(
                      lessonData[lessonIndex].bodyText,
                      250,
                      true
                    ),
                  }}
                  className={classes.bodyText}
                  paragraph={true}
                />
                <Typography variant="caption" data-cy="sub-end-warning">
                  Your subscription has ended. To continue with the lessons,
                  please subscribe on the next tab.
                </Typography>
              </Fragment>
            )}
            {selectedTab === 1 && (
              <Fragment>
                <Typography
                  variant="body1"
                  className={classes.bodyText}
                  data-cy="header"
                >
                  Subscribing is a little thing from a monetary perspective, but
                  it is a MASSIVE demonstration of your commitment to the
                  Course, and your own personal development. Individuals who
                  purchase a subscription are far more likely to keep up with
                  the daily exercises and reap the massive benefits that are
                  possible from the Course. How about more stillness and peace
                  in your life? An overall confidence in what is possible? More
                  happiness and fulfilment? All of this is possible through
                  training the mind...
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.bodyText}
                  data-cy="body"
                >
                  If you would like to continue using Badass ACIM, then please
                  choose an option below.
                </Typography>
                <SubscriptionOptions />
              </Fragment>
            )}
          </Paper>
        </main>
      </>
    );
  }

  return (
    <>
      <main className={classes.main} {...handlers}>
        <Paper className={classes.paper}>
          <AppBar position="static">
            <Tabs
              value={selectedTab}
              centered={true}
              onChange={handleChange}
              aria-label="simple tabs lesson"
            >
              <Tab label="Text" {...a11yProps(0)} data-cy="tabHeader1" />
              <Tab label="Exercise" {...a11yProps(1)} data-cy="tabHeader2" />
              {userData.subscription.status === "trialing" ? (
                <Tab label="Subscribe" {...a11yProps(2)} data-cy="tabHeader3" />
              ) : null}
            </Tabs>
          </AppBar>
          {selectedTab === 0 && (
            <Fragment>
              <Typography variant="subtitle1" data-cy="lessonNo">
                Lesson No. {userData.currentLesson}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: lessonData[lessonIndex].title.toUpperCase(),
                }}
              />
              <Typography
                variant="caption"
                display="block"
                dangerouslySetInnerHTML={{
                  __html: lessonData[lessonIndex].quoteText,
                }}
              />
              <Typography
                variant="caption"
                className={classes.quoteAuthor}
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: lessonData[lessonIndex].quoteAuthor,
                }}
              />
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: lessonData[lessonIndex].bodyText,
                }}
                className={classes.bodyText}
                paragraph={true}
              />
              {displayWarnings()}
            </Fragment>
          )}
          {selectedTab === 1 && (
            <Fragment>
              <Typography variant="h6">Summary & Exercises</Typography>
              <Typography variant="subtitle1" data-cy="exerciseLessonNo">
                Lesson No. {userData.currentLesson} -{" "}
                {lessonData[lessonIndex].summaryTitle}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.reminderHeading}
              >
                {lessonData[lessonIndex].dailyReps > 0
                  ? `${lessonData[lessonIndex].dailyReps} times today I will take a few moments to recall: `
                  : `${lessonData[lessonIndex].hourlyReps} times each hour today I will take a few moments to recall: `}
              </Typography>
              <Typography
                variant="body1"
                className={classes.bodyText}
                dangerouslySetInnerHTML={{
                  __html: lessonData[lessonIndex].summaryText,
                }}
              />
              <Reminder userData={userData} lessonIndex={lessonIndex} />
              <Grid container className={classes.buttonContainer} spacing={2}>
                {userData.currentLesson === 1 ? (
                  <Grid item xs={6}></Grid>
                ) : (
                  <Fragment>
                    <Grid item xs={6}>
                      <Button
                        data-cy={"lesson-back-button"}
                        variant={"contained"}
                        color={"primary"}
                        className={classes.floatRight}
                        onClick={() => markCompleted("Back")}
                      >
                        <ArrowBackIcon />
                        <Typography>Last Lesson</Typography>
                      </Button>
                    </Grid>
                  </Fragment>
                )}
                {userData.currentLesson === 365 ? (
                  <Grid item xs={6}></Grid>
                ) : (
                  <Fragment>
                    <Grid item xs={6}>
                      <Button
                        data-cy={"lesson-forward-button"}
                        onClick={() => markCompleted("Forward")}
                        variant={"contained"}
                        color={"primary"}
                      >
                        <Typography>Next Lesson</Typography>
                        <ArrowForwardIcon />
                      </Button>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
              {/*<FormControlLabel*/}
              {/*  control={*/}
              {/*    <Switch*/}
              {/*      checked={completed}*/}
              {/*      onChange={markCompleted}*/}
              {/*      name="completed"*/}
              {/*      color="primary"*/}
              {/*      data-cy="completedSwitch"*/}
              {/*    />*/}
              {/*  }*/}
              {/*  label={!completed ? "Mark Lesson Complete" : "Lesson Completed"}*/}
              {/*/>*/}
              {/*{completed ? (*/}
              {/*  <Typography variant="caption">*/}
              {/*    You will advance to the next lesson momentarily. If you made a*/}
              {/*    mistake, you can mark this lesson as not completed using the*/}
              {/*    slider.*/}
              {/*  </Typography>*/}
              {/*) : null}*/}
              {displayWarnings()}
            </Fragment>
          )}
          {selectedTab === 2 && (
            <Fragment>
              <Typography
                variant="body1"
                className={classes.bodyText}
                data-cy="header"
              >
                Subscribing is a little thing from a monetary perspective, but
                it is a MASSIVE demonstration of your commitment to the Course,
                and your own personal development. Individuals who purchase a
                subscription are far more likely to keep up with the daily
                exercises and reap the massive benefits that are possible from
                the Course. How about more stillness and peace in your life? An
                overall confidence in what is possible? More happiness and
                fulfilment? All of this is possible through training the mind...
              </Typography>
              <Typography
                variant="body1"
                className={classes.bodyText}
                data-cy="body"
              >
                If you would like to continue using Badass ACIM, then please
                choose an option below.
              </Typography>
              <SubscriptionOptions />
            </Fragment>
          )}
        </Paper>
        <Dialog open={pwaAlert} onClose={handleAlertClose}>
          <PWAInstallation />
          <DialogActions>
            <FormControlLabel
              control={
                <Switch
                  checked={userData.pwaAcknowledged}
                  onChange={pwaAlertHide}
                  name={"pwaAlertHide"}
                  color={"primary"}
                  data-cy={"alert-hide-switch"}
                />
              }
              label={"Please don't show this again"}
            />
          </DialogActions>
        </Dialog>
      </main>
    </>
  );
};

const styles = (theme) => ({
  main: {
    width: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  CircularProgress: {
    marginTop: "15%",
  },
  quoteAuthor: {
    "align-self": "flex-end",
    "margin-bottom": theme.spacing(2),
  },
  bodyText: {
    "& p:not(:first-child)": {
      margin: "16px 0px",
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  reminderHeading: {
    "align-self": "flex-start",
    marginTop: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  notifyCard: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
  },
  floatRight: {
    float: "right",
  },
});

export default withRouter(withStyles(styles)(DailyLesson));
