import React, { useRef, useState } from "react";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Grid,
  Snackbar,
  Typography,
  withStyles,
  TextField,
} from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";

function LegalContact(props) {
  const { classes } = props;
  const [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    message: "",
  });
  const recaptchaSiteKey = "6LdwaOkZAAAAAJCJzLcLIhM9mT74XGvnOZjPWwjT";
  const recaptchaRef = useRef();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ text: "", severity: "", open: false });

  const handleFormChange = (event) => {
    setContactDetails({
      ...contactDetails,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (
      contactDetails.name === "" ||
      contactDetails.email === "" ||
      contactDetails.message === ""
    ) {
      setAlert({
        text: "All fields are required.",
        severity: "error",
        open: true,
      });
      setLoading(false);
    } else {
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      console.log(token);

      const data = { ...contactDetails, token: token };

      axios({
        method: "post",
        url:
          "https://us-central1-badass-acim.cloudfunctions.net/api/contact/legal-form-post",
        headers: {
          accepts: "application/json",
        },
        data: data,
      })
        .then((response) => {
          setAlert({
            text:
              "Thank you! Your message has been received and will be responded to, if necessary, as soon as possible.",
            severity: "success",
            open: true,
          });
          setContactDetails({ name: "", email: "", message: "" });
          setLoading(false);
        })
        .catch((error) => {
          if (error === "Are you human?") {
            setAlert({
              text:
                "Are you Human?! Google doesn't seem to believe so. Perhaps try again in case it was an error.",
              severity: "error",
              open: true,
            });
          } else {
            setAlert({
              text: "Dang! An error occurred. Please try again later.",
              severity: "error",
              open: true,
            });
          }
          setLoading(false);
        });
    }
  };

  const closeAlert = () => {
    setAlert({ text: "", severity: "", open: false });
  };

  return (
    <main className={classes.main}>
      <form className={classes.form}>
        <Grid container>
          <Grid item xs={1} md={2} lg={3}></Grid>
          <Grid item xs={10} md={8} lg={6}>
            <Typography variant="h6">Contact Form</Typography>
            <Typography variant="body1" className={classes.bodyText}>
              If you have any concerns with the above clauses, then please feel
              free to contact us using the form below.
            </Typography>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="name">Name</InputLabel>
              <Input
                id="name"
                name="name"
                autoComplete="off"
                value={contactDetails.name}
                onChange={(e) => handleFormChange(e)}
                required
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                type="email"
                id="email"
                name="email"
                autoComplete="off"
                value={contactDetails.email}
                onChange={(e) => handleFormChange(e)}
                required
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              {/*<InputLabel htmlFor="message">Message</InputLabel>*/}
              <TextField
                id="message"
                name="message"
                multiline
                label="Message"
                placeholder="Message"
                rowsMax={6}
                value={contactDetails.message}
                onChange={(e) => handleFormChange(e)}
                required
              ></TextField>
            </FormControl>
            <ReCAPTCHA
              sitekey={recaptchaSiteKey}
              size="invisible"
              ref={recaptchaRef}
            />
            <Button
              type="button"
              data-cy="submit-button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={onSubmit}
              className={classes.submit}
              disabled={loading}
            >
              {loading ? "Please wait..." : "Send Message"}
            </Button>
          </Grid>
          <Grid item xs={1} md={2} lg={3}></Grid>
        </Grid>
      </form>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          data-cy="settingsAlert"
          severity={alert.severity}
          variant="filled"
          onClose={closeAlert}
        >
          {alert.text}
        </MuiAlert>
      </Snackbar>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "100%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  bodyText: {
    "& p:not(:first-child)": {
      margin: "16px 0px",
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    marginBottom: theme.spacing(10),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

export default withStyles(styles)(LegalContact);
