// Remove the next three lines when the content on this page is restored.
// import React, { useContext } from 'react'
// import { Typography, Paper, Avatar} from '@material-ui/core'
// import { withRouter,Redirect} from 'react-router-dom'
// Uncomment the next three lines when the content on this page is restored
import React, { useState, useContext } from "react";
import {
  Typography,
  Paper,
  Avatar,
  Button,
  FormControl,
  Input,
  InputLabel,
  Snackbar,
} from "@material-ui/core";
import { Link, withRouter, Redirect } from "react-router-dom";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import withStyles from "@material-ui/core/styles/withStyles";
import firebase from "../../config/firebase";
import { AuthContext } from "../../providers/Auth";
import MuiAlert from "@material-ui/lab/Alert";

function Register(props) {
  const { classes } = props;
  const { currentUser } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [errorAlertBool, setErrorAlertBool] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const onRegister = async () => {
    setLoading(true);
    await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (user) => {
        const waitNow = firebase.functions().httpsCallable("waitNow");
        waitNow().then(async () => {
          await firebase.auth().currentUser.sendEmailVerification();
          await firebase.auth().currentUser.updateProfile({
            displayName: name,
          });
          setRedirect(true);
        });
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          setErrorAlert(
            "Are you sure that you have entered a valid email? We seem to be having great difficulty verifying it."
          );
          setErrorAlertBool(true);
        } else if (error.code === "auth/weak-password") {
          setErrorAlert("Password needs to be 6 or more characters.");
          setErrorAlertBool(true);
        } else {
          setErrorAlert(
            "Hmmm... are you sure that you NEED to register? Perhaps you need to login instead??"
          );
          setErrorAlertBool(true);
        }
        setLoading(false);
      });
  };

  const closeAlert = () => {
    setErrorAlertBool(false);
    setErrorAlert("");
  };

  if (currentUser) {
    return <Redirect to="/daily-lesson" />;
  }

  if (redirect) {
    return props.history.replace("/daily-lesson");
  }

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          Register Account
        </Typography>
        <Typography variant="bodytext1" gutterBottom>
          According to Buddhism, 'All compounded phenomena is impermanent'. Unfortunately this also applies to Badass ACIM which is being retired, and will cease to exist on 31 December 2024. Existing users can still access the
            lessons, as well as all functionality, until this time and whilst their subscription is active; but we will not be renewing subscriptions from 31 August 2023 nor accepting new users. We apologise for the inconvenience.
          </Typography>
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            component={Link}
            to="/login"
            className={classes.submit}
        >
          Go back to Login
        </Button>
        {/*<Typography variant="bodytext1" gutterBottom>*/}
        {/*  You can sign up for Badass ACIM using the form below. All fields are*/}
        {/*  required.*/}
        {/*</Typography>*/}
        {/*<Typography variant="bodytext1" gutterBottom>*/}
        {/*  Why do I need to register? Please see our{" "}*/}
        {/*  <Link to={"/register-faq"}>registration FAQ's</Link> for details.*/}
        {/*</Typography>*/}
        {/*<form*/}
        {/*  className={classes.form}*/}
        {/*  onSubmit={(e) => e.preventDefault() && false}*/}
        {/*>*/}
        {/*  <FormControl margin="normal" required fullWidth>*/}
        {/*    <InputLabel htmlFor="name">Name</InputLabel>*/}
        {/*    <Input*/}
        {/*      id="name"*/}
        {/*      name="name"*/}
        {/*      autoComplete="off"*/}
        {/*      autoFocus*/}
        {/*      value={name}*/}
        {/*      onChange={(e) => setName(e.target.value)}*/}
        {/*      required*/}
        {/*    />*/}
        {/*  </FormControl>*/}
        {/*  <FormControl margin="normal" required fullWidth>*/}
        {/*    <InputLabel htmlFor="email">Email Address</InputLabel>*/}
        {/*    <Input*/}
        {/*      id="email"*/}
        {/*      name="email"*/}
        {/*      autoComplete="off"*/}
        {/*      value={email}*/}
        {/*      onChange={(e) => setEmail(e.target.value)}*/}
        {/*      required*/}
        {/*    />*/}
        {/*  </FormControl>*/}
        {/*  <FormControl margin="normal" required fullWidth>*/}
        {/*    <InputLabel htmlFor="password">Password</InputLabel>*/}
        {/*    <Input*/}
        {/*      name="password"*/}
        {/*      type="password"*/}
        {/*      id="password"*/}
        {/*      autoComplete="off"*/}
        {/*      value={password}*/}
        {/*      onChange={(e) => setPassword(e.target.value)}*/}
        {/*      required*/}
        {/*    />*/}
        {/*    {password.length > 0 && password.length < 6 ? (*/}
        {/*      <Typography variant="caption" className={classes.hintText}>*/}
        {/*        Password must be longer than 6 characters*/}
        {/*      </Typography>*/}
        {/*    ) : null}*/}
        {/*  </FormControl>*/}
        {/*  <Button*/}
        {/*    type="submit"*/}
        {/*    data-cy="register-button"*/}
        {/*    fullWidth*/}
        {/*    variant="contained"*/}
        {/*    color="primary"*/}
        {/*    onClick={onRegister}*/}
        {/*    className={classes.submit}*/}
        {/*    disabled={loading}*/}
        {/*  >*/}
        {/*    {loading ? "Please wait..." : "Register"}*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*    type="submit"*/}
        {/*    fullWidth*/}
        {/*    variant="contained"*/}
        {/*    color="secondary"*/}
        {/*    component={Link}*/}
        {/*    to="/login"*/}
        {/*    className={classes.submit}*/}
        {/*  >*/}
        {/*    Go back to Login*/}
        {/*  </Button>*/}
        {/*</form>*/}
      </Paper>
      <Snackbar
        open={errorAlertBool}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          data-cy="errorAlert"
          severity="error"
          variant="filled"
          onClose={closeAlert}
        >
          {errorAlert}
        </MuiAlert>
      </Snackbar>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  hintText: {
    color: "darkgrey",
  },
});

export default withRouter(withStyles(styles)(Register));
