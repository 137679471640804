import React from "react";
import {
  Typography,
  Paper,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import CIMExperiments from "../assets/cimexperiments.jpg";

function AboutPam(props) {
  const { classes } = props;

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              About Pam
            </Typography>
            <Typography variant="body1" gutterBottom>
              Pam Grout is a world traveler, a loving mother, a best-selling
              author, a millionaire and an inspiring witness to everyone she
              meets.
            </Typography>
            <img id="pamImage" src="../assets/welcome-photo.jpg" alt="" />
            <Typography variant="body1" gutterBottom>
              Actually, only four of the above are true so far, but that line is
              an affirmation I started using 20 years ago before I’d ever had a
              child, before I became a travel writer, or an author and, for that
              matter, before I even liked myself all that much. Evidently
              affirmations work, because now I can proudly say all but one of
              the above are true. I’ll let you guess which one is yet to
              manifest.
            </Typography>
            <Typography variant="body1" gutterBottom>
              I live in Lawrence, Kansas, a fun, funky university town that has
              lots of artists, liberals and other like-minded folk. I love
              anything having to do with creativity. Maybe that’s why my second
              book was about how to be more creative. I play tennis, I love to
              garden (although I refuse chemical help of all kind), I write
              screenplays (unfortunately nobody buys them…..YET) and I love to
              putter. Putter, in case you’re unfamiliar with the term, means
              doing very little. I like just hanging out, talking with friends,
              reading books with my daughter.
            </Typography>
            <Typography variant="body1" gutterBottom>
              For a career, I write articles and books. I have 15 published
              books so far and I’ve sold articles to dozens of publications. The
              ones I’m probably most proud of are Travel & Leisure, Outside,
              Family Circle, Modern Maturity, New Age Journal, Scientific
              American Explorations, Arizona Highways, Travel Holiday, Tennis,
              Powder, Snow Country, the Washington Post, the Detroit Free Press,
              First for Women, Amtrak Express and on and on.
            </Typography>
            <Typography variant="body1" gutterBottom>
              I’m a Midwestern stringer for People magazine. Working out of the
              Chicago Bureau, I’ve written about everything from a dinosaur
              hunter to a couple guys who opened a bakery for dogs to a
              wonderful Boeing lineman who ran into a burning building to save
              six children.
            </Typography>
            <Typography variant="body1" gutterBottom>
              I also write a travel column called “Now, Where Was I?” I’ve been
              to all the continents and I’ve tried practically all the sports.
              I’ve yet to jump out of an airplane, but I have to save something
              for my 90th birthday.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Pam's Books
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardActionArea>
                  <a
                    data-cy="esquared"
                    href="https://www.amazon.com/Squared-Pam-Grout-ebook/dp/B00B2JSRAS/ref=sr_1_2?dchild=1&keywords=pam+grout&qid=1602816091&refinements=p_n_feature_browse-bin%3A618073011&rnid=618072011&s=books&sr=1-2"
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      className={classes.media}
                      image="assets/e2.jpg"
                      title="E Squared"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        E Squared
                      </Typography>
                    </CardContent>
                  </a>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardActionArea>
                  <a
                    data-cy="ecubed"
                    href="https://www.amazon.com/Cubed-Pam-Grout-ebook/dp/B00KSF7VN6/ref=sr_1_4?dchild=1&keywords=pam+grout&qid=1602816091&refinements=p_n_feature_browse-bin%3A618073011&rnid=618072011&s=books&sr=1-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      className={classes.media}
                      image="assets/e3.jpg"
                      title="E Cubed"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        E Cubed
                      </Typography>
                    </CardContent>
                  </a>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardActionArea>
                  <a
                    data-cy="artsoul"
                    href="https://www.amazon.com/Art-Soul-Reloaded-Pam-Grout-ebook/dp/B072N48TTD/ref=sr_1_6?dchild=1&keywords=pam+grout&qid=1602816091&refinements=p_n_feature_browse-bin%3A618073011&rnid=618072011&s=books&sr=1-6"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      className={classes.media}
                      image="assets/artsout.jpg"
                      title="Art & Soul Reloaded"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Art & Soul Reloaded
                      </Typography>
                    </CardContent>
                  </a>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardActionArea>
                  <a
                    data-cy="thankgrow"
                    href="https://www.amazon.com/Thank-Grow-Rich-Pam-Grout-ebook/dp/B01FL66LDM/ref=sr_1_5?dchild=1&keywords=pam+grout&qid=1602816091&refinements=p_n_feature_browse-bin%3A618073011&rnid=618072011&s=books&sr=1-5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      className={classes.media}
                      image="assets/thank.jpg"
                      title="Thank & Grow Rich"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Thank & Grow Rich
                      </Typography>
                    </CardContent>
                  </a>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardActionArea>
                  <a
                    data-cy="livingbig"
                    href="https://www.amazon.com/Living-Big-Embrace-Passion-Extraordinary-ebook/dp/B07YHT8V3B/ref=sr_1_7?dchild=1&keywords=pam+grout&qid=1602816091&refinements=p_n_feature_browse-bin%3A618073011&rnid=618072011&s=books&sr=1-7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      className={classes.media}
                      image="assets/livingbig.jpg"
                      title="Living Big"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Living Big
                      </Typography>
                    </CardContent>
                  </a>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardActionArea>
                  <a
                    data-cy="jumpstart"
                    href="https://www.amazon.com/Jumpstart-Your-Metabolism-Changing-Breathe-ebook/dp/B0048WPO2A/ref=sr_1_3?dchild=1&keywords=pam+grout&qid=1602816091&refinements=p_n_feature_browse-bin%3A618073011&rnid=618072011&s=books&sr=1-3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      className={classes.media}
                      image="assets/jumpstart.jpg"
                      title="Jumpstart Your Metabolism"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Jumpstart Your Metabolism
                      </Typography>
                    </CardContent>
                  </a>
                </CardActionArea>
              </Card>
              <Card>
                <CardActionArea>
                  <a
                    data-cy="cimexperiment"
                    href="https://www.amazon.com/Course-Miracles-Experiment-Rewiring-Therefore-ebook/dp/B07X1FHXTM/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1606008761&sr=8-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      className={classes.media}
                      image={CIMExperiments}
                      title="The Course in Miracles Experiments"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        The Course in Miracles Experiments
                      </Typography>
                    </CardContent>
                  </a>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  media: {
    height: "60vh",
  },
});

export default withStyles(styles)(AboutPam);
