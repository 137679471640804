import React, { useContext, useState } from "react";
import firebase from "../../config/firebase";
import { useStripe } from "@stripe/react-stripe-js";
import { Button, Snackbar } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import stripe_payments from "../../assets/stripe_payments.png";
import MuiAlert from "@material-ui/lab/Alert";
import { AuthContext } from "../../providers/Auth";

function Stripe(props) {
  const { classes, selectedSubscription } = props;
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ text: "", severity: "", open: false });
  const { currentUser } = useContext(AuthContext);

  const createCheckoutSession = () => {
    setLoading(true);
    const createCheckout = firebase
      .functions()
      .httpsCallable("createCheckoutSession");
    createCheckout({ priceId: selectedSubscription.priceId })
      .then((result) => {
        stripe
          .redirectToCheckout({
            sessionId: result.data.responseId,
          })
          .then((result1) => {
            console.log(result1);
          });
      })
      .catch((error) => {
        setAlert({
          text:
            "I'm dreadfully sorry, but our server seems to be having a moment. Please try again later.",
          severity: "error",
          open: true,
        });
        setLoading(false);
      });
  };

  const closeAlert = () => {
    setAlert({ text: "", severity: "", open: false });
  };

  return (
    <main className={classes.main}>
      <form>
        <span>
          <Button
            type="button"
            onClick={createCheckoutSession}
            disabled={loading || !stripe}
            data-cy="payment-redirect-button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {!loading
              ? `Subscribe for $${selectedSubscription.price.toFixed(2)} per ${
                  selectedSubscription.term
                }`
              : "Processing... please wait."}
          </Button>
          <img
            src={stripe_payments}
            className={classes.stripePayLogo}
            alt="Stripe Payments Logo"
            data-cy="stripeLogo"
          />
        </span>
      </form>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          data-cy="settingsAlert"
          severity={alert.severity}
          variant="filled"
          onClose={closeAlert}
        >
          {alert.text}
        </MuiAlert>
      </Snackbar>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  headers: {
    marginTop: theme.spacing(3),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  paymentForm: {
    marginTop: theme.spacing(2),
  },
  stripePayLogo: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    marginTop: theme.spacing(1),
  },
});

export default withStyles(styles)(Stripe);
