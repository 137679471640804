import React, { useState, createContext, useEffect } from "react";
import app from "../config/firebase"

// Create Context Object
export const AuthContext = createContext();

// Create a provider for the components to consume and subscribe to changes
export const AuthProvider = ({children}) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    app.auth().onAuthStateChanged(setCurrentUser)
  },[])

  return (
    <AuthContext.Provider value={{currentUser}}>
      {children}
    </AuthContext.Provider>
  );
};




