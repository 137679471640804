import React, { useContext, useState } from "react";
import {
  Typography,
  Paper,
  Avatar,
  Button,
  FormControl,
  Input,
  InputLabel,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link, Redirect } from "react-router-dom";
import { AuthContext } from "../providers/Auth";
import app from "../config/firebase";

function SignIn(props) {
  const { classes } = props;
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [errorAlertBool, setErrorAlertBool] = useState(false);
  const [loading, setLoading] = useState(false);

  async function login() {
    setLoading(true);
    try {
      await app.auth().signInWithEmailAndPassword(email.trim(), password);
      props.history.replace("/daily-lesson");
      setLoading(false);
    } catch (error) {
      // console.log(error)
      // User Not Found
      if (error.code === "auth/user-not-found") {
        setErrorAlert(
          "Hmmm... We can find your email address in the Akashic Records. Did you need to register instead?"
        );
        setErrorAlertBool(true);
      } else if (error.code === "auth/wrong-password") {
        setErrorAlert(
          "Dang! That didn't work... did you type your password correctly? Perhaps reset it if you keep seeing this."
        );
        setErrorAlertBool(true);
      } else {
        setErrorAlert(
          "Are you sure you have an account? As that didn't work... perhaps you need to register instead?"
        );
        setErrorAlertBool(true);
      }
      setLoading(false);
    }
  }

  const closeAlert = () => {
    setErrorAlertBool(false);
    setErrorAlert("");
  };

  if (currentUser) {
    return <Redirect to="/daily-lesson" />;
  }

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          onSubmit={(e) => e.preventDefault() && false}
        >
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              id="email"
              name="email"
              autoComplete="off"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              name="password"
              type="password"
              id="password"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </FormControl>
          <Button
            type="submit"
            data-cy="login-button"
            fullWidth
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={login}
            className={classes.submit}
          >
            {loading ? "Please wait..." : "Sign In"}
          </Button>
          <Button
            type="submit"
            data-cy="register-button"
            fullWidth
            variant="contained"
            color="secondary"
            component={Link}
            to="/register"
            className={classes.submit}
          >
            Register
          </Button>
          <Typography
            component="p"
            variant="body1"
            className={classes.textLink}
          >
            Forgotten your Password?{" "}
            <Link to={"/reset-password"}>Click HERE to reset it.</Link>
          </Typography>
        </form>
        <Snackbar
          open={errorAlertBool}
          autoHideDuration={6000}
          onClose={closeAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            data-cy="errorAlert"
            severity="error"
            variant="filled"
            onClose={closeAlert}
          >
            {errorAlert}
          </MuiAlert>
        </Snackbar>
      </Paper>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  textLink: {
    marginTop: theme.spacing(3),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

export default withStyles(styles)(SignIn);
