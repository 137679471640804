import React, { Fragment, useContext, useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Switch,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import firebase from "../../config/firebase";
import { AuthContext } from "../../providers/Auth";
import MuiAlert from "@material-ui/lab/Alert";

function LessonSettings(props) {
  const { classes, userData } = props;
  const { currentUser } = useContext(AuthContext);
  const [selectedLessonNumber, setSelectedLessonNumber] = useState(
    userData.currentLesson
  );
  const [openSelect, setOpenSelect] = useState(false);
  const [openConfirmationAlert, setOpenConfirmationAlert] = useState(false);
  const [alert, setAlert] = useState({ text: "", severity: "", open: false });
  const [offlineMode, setOfflineMode] = useState(false);
  const [offlineModeMsg, setOfflineModeMsg] = useState(null);

  const db = firebase.firestore();
  const auth = firebase.auth();

  useEffect(() => {
    createCompletedLessonArray();
    checkOfflineMode();
  }, []);

  const createCompletedLessonArray = () => {
    return (
      <Fragment>
        <TextField
          id={"reset-lesson-field"}
          value={selectedLessonNumber}
          onChange={handleChange}
          type={"number"}
          label={"Reset current lesson to..."}
          min="1"
          max={"365"}
          step={"1"}
          InputProps={{ inputProps: { min: 1, max: 365 } }}
        />
      </Fragment>
    );
  };

  const checkOfflineMode = () => {
    if (typeof Storage !== "undefined") {
      if (localStorage.getItem("offlineMode") === "true") {
        setOfflineMode(true);
      }
      const offModeMsg = localStorage.getItem("offlineModeMsg");
      if (offModeMsg !== undefined) {
        setOfflineModeMsg(offModeMsg);
      }
    }
  };

  const handleClose = () => {
    setOpenSelect(false);
  };

  const handleOpen = () => {
    setOpenSelect(true);
  };

  const handleChange = (event) => {
    setSelectedLessonNumber(event.target.value);
  };

  const handleConfirmationClose = () => {
    setOpenConfirmationAlert(false);
  };

  const resetProgress = async () => {
    let resetLessonNumber = Math.floor(selectedLessonNumber);
    if (resetLessonNumber > 365) {
      resetLessonNumber = 365;
    }
    if (resetLessonNumber < 1) {
      resetLessonNumber = 1;
    }
    const newCompletedLessonsArray = [];
    for (let i = 1; i < resetLessonNumber; i++) {
      newCompletedLessonsArray.push(i);
    }
    db.collection("users")
      .doc(`${currentUser.uid}`)
      .update({
        completedLessons: newCompletedLessonsArray,
        currentLesson: resetLessonNumber,
        "messaging.upcoming_reminders": [],
        "messaging.next_reminder": null,
      })
      .catch((error) => {
        console.log(error);
        setAlert({
          text: "Dang! An error occurred. Please try again later.",
          severity: "error",
          open: true,
        });
      });
    handleConfirmationClose();
    setSelectedLessonNumber(resetLessonNumber);
    setAlert({
      text:
        "Your progress has been reset. Please review the daily lesson page.",
      severity: "success",
      open: true,
    });
  };

  const updateOfflinePersistence = (event) => {
    if (event.target.checked === true) {
      if (typeof Storage !== "undefined") {
        localStorage.setItem("offlineMode", "true");
        setOfflineMode(true);
        setAlert({
          text:
            "Offline Mode has been requested. You will be logged out shortly to enable the setting.",
          severity: "success",
          open: true,
        });
        setTimeout(() => {
          auth.signOut();
        }, 5000);
      } else {
        console.log("localStorage Unavailable");
        setAlert({
          text:
            "Dang! It appears that your browser doesn't support offline mode. Please try another browser such as Chrome, Firefox or Edge.",
          severity: "error",
          open: true,
        });
      }
    } else {
      localStorage.setItem("offlineMode", "false");
      setOfflineMode(false);
      setAlert({
        text:
          "Offline Mode has been disabled. You will be logged out shortly to correctly disable the setting",
        severity: "success",
        open: true,
      });
      setTimeout(() => {
        auth.signOut();
      }, 5000);
    }
  };

  const cancelReset = () => {
    setSelectedLessonNumber(1);
    handleConfirmationClose();
  };

  const closeAlert = () => {
    setAlert({ text: "", severity: "", open: false });
  };

  return (
    <Fragment>
      <Typography variant="h6" className={classes.headers} data-cy="header">
        Lesson Settings
      </Typography>
      <Typography variant="h6" className={classes.headers} data-cy="header">
        Enable Offline Mode
      </Typography>
      <Typography variant="body1" className={classes.bodyText} data-cy="body1">
        Offline mode will allow Badass ACIM to work even when you are not
        connected to the internet such as when on a plane, or on retreat. The
        only caveat is that reminders don't work in offline mode... so please be
        aware that any reminders you have set will require internet access.
        Also, please note that setting offline support isn't recommended on
        public or shared devices (ie, at a public library); but on your own
        phone or tablet it'll be grand.
      </Typography>
      {offlineModeMsg === null ? (
        <Fragment>
          <FormControlLabel
            control={
              <Switch
                checked={offlineMode}
                onChange={updateOfflinePersistence}
                name="offline-persistence-switch"
                data-cy="offline-persistence-swtich"
                color="primary"
              />
            }
            label={offlineMode ? "Disable Offline Mode" : "Enable Offline Mode"}
          />
          <Typography
            variant="caption"
            className={classes.bodyText}
            data-cy="warningText"
          >
            <i>{`Note: ${
              offlineMode ? "Disabling" : "Enabling"
            } offline mode will log you out of the app. This is necessary in order to update the settings correctly.`}</i>
          </Typography>
        </Fragment>
      ) : (
        <Typography
          variant="body1"
          className={classes.bodyText}
          data-cy="body1"
        >
          {offlineModeMsg}
        </Typography>
      )}
      <Typography variant="h6" className={classes.headers} data-cy="header">
        Reset Lesson Progress
      </Typography>
      <Typography
        variant="body1"
        className={classes.bodyText}
        data-cy="reset-text1"
      >
        Want to restart your progress in Badass ACIM? Perhaps catch up to where
        you are in the book? No Problems! Just use the controls below and we'll
        have you rocking it in no time.
      </Typography>
      {userData.completedLessons.length >= 0 ? (
        <Fragment>
          <FormControl className={classes.formControl} data-cy="dropdown">
            {createCompletedLessonArray()}
          </FormControl>
          <FormControl>
            <Button
              className={classes.button}
              type="submit"
              data-cy="reset-progress-button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenConfirmationAlert(true);
              }}
            >
              Reset Progress
            </Button>
          </FormControl>{" "}
        </Fragment>
      ) : (
        <Typography
          variant="body1"
          className={classes.bodyText}
          data-cy="body1"
        >
          I'm sorry but you need to complete some lessons PRIOR to resetting
          progress... it is just the limited, time-bound manner in which
          computers work! Once you have completed some lessons then return here
          anytime you want to reset your progress.
        </Typography>
      )}
      <Dialog open={openConfirmationAlert} onClose={handleConfirmationClose}>
        <DialogTitle id="confirmation-alert-title">Are you Sure??!</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-alert-text">
            As the course notes, everything is impermanent EXCEPT this. Once you
            have confirmed this action you will irrevocably start afresh from
            your chosen lesson.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={resetProgress}
            data-cy="continueReset"
          >
            I'm sure... Reset!
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={cancelReset}
            data-cy="cancelReset"
          >
            Ummm... maybe not
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          data-cy="settingsAlert"
          severity={alert.severity}
          variant="filled"
          onClose={closeAlert}
        >
          {alert.text}
        </MuiAlert>
      </Snackbar>
    </Fragment>
  );
}

const styles = (theme) => ({
  bodyText: {
    marginTop: theme.spacing(1),
  },
  headers: {
    marginTop: theme.spacing(3),
  },
  button: {
    display: "inline-block",
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
});

export default withRouter(withStyles(styles)(LessonSettings));
