import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/messaging";
import "firebase/functions";

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
});

// firebase.functions().useFunctionsEmulator("http://localhost:5001");
//
// firebase.firestore().settings({
//   host: "localhost:8080",
//   ssl: false,
// });

if (localStorage.getItem("offlineMode") !== undefined) {
  if (localStorage.getItem("offlineMode") === "true") {
    app
      .firestore()
      .enablePersistence({ synchronizeTabs: true })
      .catch((err) => {
        localStorage.setItem("offlineMode", "false");
        localStorage.setItem(
          "offlineModeMsg",
          "Offline persistence isn't supported by this browser. If offline support is necessary, then please try another browser such as Chrome, Firefox or Edge."
        );
      });
  } else {
    app.firestore().clearPersistence();
  }
}

export default app;
