import React, { Fragment } from "react";
import {
  Typography,
  withStyles,
  List,
  ListItem,
  ListItemIcon,
  DialogTitle,
  DialogContent,
  ListItemText,
  Button,
} from "@material-ui/core";

import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import AirplanemodeInactiveIcon from "@material-ui/icons/AirplanemodeInactive";
import GetAppIcon from "@material-ui/icons/GetApp";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import CodeIcon from "@material-ui/icons/Code";
import { Link } from "react-router-dom";

function ChangeLesson(props) {
  const { classes } = props;

  return (
    <Fragment>
      <DialogTitle id={"pwa-alert-title"}>
        How do I change my lesson number?
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          className={classes.bodyText}
          data-cy="support-content1"
        >
          Need to manually change your lesson number so that you can kick off
          where you are up to in the real world? Perhaps would like to go back a
          touch to synchronise with a group? Too easy...
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          All you need to do is click the button below which will take you to
          the settings page. There you can enter the lesson number that you
          would like to reset to, and then confirm that you do indeed want to
          reset. Click your heels... and you are there! :-)
        </Typography>
        <Button
          type="button"
          data-cy="submit-button"
          fullWidth
          variant="contained"
          color="primary"
          component={Link}
          to={"/settings/lesson"}
          className={classes.submit}
        >
          Go to Reset Progress
        </Button>
      </DialogContent>
    </Fragment>
  );
}

const styles = (theme) => ({
  main: {
    width: "100%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  bodyText: {
    "& p:not(:first-child)": {
      margin: "16px 0px",
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

export default withStyles(styles)(ChangeLesson);
