import React, { Fragment, useState } from "react";
import firebase from "../../config/firebase";
import { Button, Snackbar, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import stripe_payments from "../../assets/stripe_payments.png";
import MuiAlert from "@material-ui/lab/Alert";

function Dashboard(props) {
  const { classes, userData } = props;
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ text: "", severity: "", open: false });
  const [redirectUrl, setRedirectUrl] = useState("");

  const manageSubscription = () => {
    setLoading(true);
    const dashboardSession = firebase
      .functions()
      .httpsCallable("manageDashboardSession");
    dashboardSession()
      .then((response) => {
        setRedirectUrl(response.data.url);
      })
      .catch((error) => {
        if (error.message === "internal") {
          setAlert({
            text:
              "Ah! It seems to me that your internet connectivity may be down. Please try again later.",
            severity: "error",
            open: true,
          });
        } else {
          setAlert({
            text:
              "Hmmm... not sure why that didn't work. Please try again later.",
            severity: "error",
            open: true,
          });
        }

        setLoading(false);
      });
  };

  const closeAlert = () => {
    setAlert({ text: "", severity: "", open: false });
  };

  const dashboardDisplay = () => {
    if (userData.subscription.priceId.includes("droid")) {
      return (
        <Fragment>
          <p data-cy="body1">
            Your current subscription seems to have been purchased via the{" "}
            <strong>Google Play Store</strong>. You will need to manage your
            subscription via that interface. Please use the button below to go
            to the store, then select "My Subscriptions".
          </p>
          <a
            href="https://play.google.com/store/account/subscriptions"
            target="__blank"
            rel="noopener noreferrer"
          >
            <Button
              data-cy="manage-subscription-button"
              fullWidth
              variant="contained"
              color="primary"
              // onClick={() => {}}
              className={classes.submit}
            >
              Go to Google Play
            </Button>
          </a>
        </Fragment>
      );
    } else if (userData.subscription.priceId.includes("ios")) {
      return (
        <Fragment>
          <p data-cy="body1">
            Your current subscription seems to have been purchased via the{" "}
            <strong>Apple App Store</strong>. You will need to manage your
            subscription via that interface. Please go to settings on your Apple
            device, then "Subscriptions". If you need assistance, the button
            below will take you to the Apple support page.
          </p>
          <a
            href="https://support.apple.com/en-us/HT202039"
            target="__blank"
            rel="noopener noreferrer"
          >
            <Button
              data-cy="manage-subscription-button"
              fullWidth
              variant="contained"
              color="primary"
              // onClick={() => {}}
              className={classes.submit}
            >
              Go to Apple Support Page
            </Button>
          </a>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Button
            data-cy="manage-subscription-button"
            fullWidth
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={manageSubscription}
            className={classes.submit}
          >
            {!loading
              ? "Subscription Dashboard"
              : "Preparing your dashboard session"}
          </Button>
          <img
            src={stripe_payments}
            className={classes.stripePayLogo}
            alt="Stripe Payments Logo"
          />
        </Fragment>
      );
    }
  };

  if (redirectUrl !== "") {
    window.location.href = redirectUrl;
  }

  return (
    <main className={classes.main}>
      {dashboardDisplay()}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          data-cy="settingsAlert"
          severity={alert.severity}
          variant="filled"
          onClose={closeAlert}
        >
          {alert.text}
        </MuiAlert>
      </Snackbar>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  stripePayLogo: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    marginTop: theme.spacing(1),
  },
});

export default withStyles(styles)(Dashboard);
