import React from "react";
import "./styles.css";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "../../config/PrivateRoute";
import { AuthProvider } from "../../providers/Auth";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Navbar from "../layout/Navbar";
import HomePage from "../HomePage";
import Login from "../Login";
import Register from "../register/Register";
import RegisterFAQ from "../register/RegisterFAQ";
import DailyLesson from "../DailyLesson/DailyLesson";
import AboutPam from "../AboutPam";
import LessonHistory from "../history/LessonHistory";
import Settings from "../Settings/Settings";
import AppSupport from "../appSupport/SupportHome";
import ForgotPassword from "../ForgotPassword";
import LessonFavourites from "../LessonFavourites";
import SubscriptionSuccess from "../Settings/SubscriptionSuccess";
import SubscriptionCancel from "../Settings/SubscriptionCancel";
import Privacy from "../legals/Privacy";
// import Terms from "../legals/Terms";
import EULA from "../legals/EULA";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ffe54c",
      main: "#ffb300",
      dark: "#c68400",
      contrastText: "#000000",
    },
    secondary: {
      light: "#6745c2",
      main: "#311a91",
      dark: "#000062",
      contrastText: "#ffffff",
    },
  },
  props: {
    MuiPaper: {
      elevation: 10,
    },
  },
});

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51HlkTbKIu9BpdiKEQLgUaqLhUNNyBr1npF3IZETjbqJhPJWKttSluEaPXKeYdm60jsSVAzczGe5jNNQ0M1i8xbXV007UJCWBgJ"
);

export default function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Elements stripe={stripePromise}>
          <Router>
            <Navbar />
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/reset-password" component={ForgotPassword} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/register-faq" component={RegisterFAQ} />
              <Route exact path="/about-pam" component={AboutPam} />
              <Route
                exact
                path="/settings/subscription-cancel"
                component={SubscriptionCancel}
              />
              <Route
                exact
                path="/settings/subscription-success/:sessionId?"
                component={SubscriptionSuccess}
              />
              <Route exact path="/legals/privacy" component={Privacy} />
              <Route exact path="/legals/terms" component={EULA} />
              <Redirect exact from="/daily-lesson" to="/daily-lesson/text" />
              <PrivateRoute
                exact
                path="/daily-lesson/:tab?"
                component={(props) => <DailyLesson {...props} />}
              />
              <PrivateRoute
                exact
                path="/lesson-history"
                component={LessonHistory}
              />
              {/*<PrivateRoute exact path="/lesson-favourites" component={LessonFavourites} />*/}
              <Redirect exact from="/settings" to="/settings/reminders" />
              <PrivateRoute
                exact
                path="/settings/:tab?"
                component={(props) => <Settings {...props} />}
              />
              <PrivateRoute exact path="/app-support" component={AppSupport} />
            </Switch>
          </Router>
        </Elements>
      </AuthProvider>
    </MuiThemeProvider>
  );
}
