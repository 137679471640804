import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Fab,
  IconButton,
  Dialog,
  Snackbar,
} from "@material-ui/core";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Add from "@material-ui/icons/Add";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiAlert from "@material-ui/lab/Alert";
import firebase from "../../config/firebase";
import { DateTime } from "luxon";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { AuthContext } from "../../providers/Auth";
import { lessonData } from "../../config/lessons";

function Reminder(props) {
  const { classes, userData, lessonIndex } = props;
  const { currentUser } = useContext(AuthContext);
  const [newReminder, setNewReminder] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");

  const db = firebase.firestore();

  const addReminder = async () => {
    setErrorAlert("");
    if (newReminder > DateTime.local()) {
      const oldReminderArray = [...userData.messaging.upcoming_reminders];
      oldReminderArray.push(newReminder.toMillis());
      oldReminderArray.sort((a, b) => {
        return a - b;
      });
      db.collection("users").doc(`${currentUser.uid}`).update({
        "messaging.upcoming_reminders": oldReminderArray,
        "messaging.next_reminder": oldReminderArray[0],
      });
    } else {
      setErrorAlert(
        "In the real world time is an illusion. In the computer world... not so much! We can't set a reminder in the past."
      );
    }
    setOpenDialog(false);
  };

  const deleteReminder = async (reminder) => {
    const oldReminderArray = [...userData.messaging.upcoming_reminders];
    const filteredReminders = oldReminderArray.filter((i) => {
      return i !== reminder;
    });
    filteredReminders.sort((a, b) => {
      return a - b;
    });
    await db.collection("users").doc(`${currentUser.uid}`).update({
      "messaging.upcoming_reminders": filteredReminders,
      "messaging.next_reminder": filteredReminders[0],
    });
  };

  const changeReminder = async (reminder, event) => {
    if (newReminder > DateTime.local()) {
      const oldReminderArray = [...userData.messaging.upcoming_reminders];
      const filteredReminders = oldReminderArray.filter((i) => {
        return i !== reminder;
      });
      const newTime = event.toMillis();
      if (!filteredReminders.includes(newTime)) {
        filteredReminders.push(event.toMillis());
      }
      filteredReminders.sort((a, b) => {
        return a - b;
      });
      await db.collection("users").doc(`${currentUser.uid}`).update({
        "messaging.upcoming_reminders": filteredReminders,
        "messaging.next_reminder": filteredReminders[0],
      });
    } else {
      setErrorAlert(
        "In the real world time is an illusion. In the computer world... not so much! We can't set a reminder in the past."
      );
    }
  };

  const defaultReminders = async () => {
    let reminderArray = [];
    let dailyReminderNumber = lessonData[lessonIndex].dailyReps;
    let hourlyReminderNumber = lessonData[lessonIndex].hourlyReps;
    const activePeriodStart = new Date();
    activePeriodStart.setHours(userData.messaging.active_period.start);
    activePeriodStart.setMinutes(0);
    const activePeriodEnd = new Date();
    activePeriodEnd.setHours(userData.messaging.active_period.end);
    activePeriodEnd.setMinutes(0);
    if (dailyReminderNumber > 0) {
      const periods = dailyReminderNumber === 1 ? 1 : dailyReminderNumber - 1;
      const reminderInterval = (activePeriodEnd - activePeriodStart) / periods;
      for (let i = 0; i < dailyReminderNumber; i++) {
        const newTime = activePeriodStart.getTime() + i * reminderInterval;
        if (newTime > DateTime.local()) {
          reminderArray.push(newTime);
        }
      }
    } else if (hourlyReminderNumber > 0) {
      const numberOfReminders =
        (userData.messaging.active_period.end -
          userData.messaging.active_period.start) *
        hourlyReminderNumber;
      const reminderInterval =
        (activePeriodEnd - activePeriodStart) / numberOfReminders;
      for (let i = 0; i < numberOfReminders; i++) {
        const newTime = activePeriodStart.getTime() + i * reminderInterval;
        if (newTime > DateTime.local()) {
          reminderArray.push(newTime);
        }
      }
    }

    await db.collection(`users`).doc(`${currentUser.uid}`).update({
      "messaging.upcoming_reminders": reminderArray,
      "messaging.next_reminder": reminderArray[0],
    });
  };

  const clearReminders = async () => {
    const emptyReminderArray = [];
    await db.collection("users").doc(`${currentUser.uid}`).update({
      "messaging.upcoming_reminders": emptyReminderArray,
      "messaging.next_reminder": null,
    });
  };

  const displayDialog = () => {
    setNewReminder(DateTime.local());
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const closeAlert = () => {
    setErrorAlert("");
  };

  if (userData.messaging.permission === false) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}>
            Scheduled Reminders
          </Typography>
          <Typography variant="body1" className={classes.bodyText}>
            Reminders are an awesome way to stay on track with your journey
            through the Course. However you need to 'allow' reminders before any
            can be sent. Go to <Link to={"/settings"}>Settings</Link> to enable
            reminders and set them up.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <main className={classes.main}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.controls}>
              <Typography variant="h6" className={classes.title}>
                Scheduled Reminders
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.bodyText}>
              <p>
                Please use the list below to customise the reminders you would
                like to receive for this lesson. You can add, delete or modify
                any of the reminders; but you can't add reminders in the past.
                Otherwise, set up as many (or as little) as you feel you need.
              </p>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.controls}>
              <Typography variant="h6" className={classes.title}></Typography>
              <Fab
                color="primary"
                size="small"
                aria-label="add"
                onClick={displayDialog}
              >
                <Add />
              </Fab>
            </div>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "16px" }}>
            {userData.messaging.upcoming_reminders.map((reminder, index) => {
              return (
                <Fragment key={reminder}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card raised={true}>
                      <CardContent>
                        <div className={classes.controls}>
                          <Typography className={classes.typo}>
                            {DateTime.fromMillis(reminder).toFormat(
                              "EEE, d MMM"
                            )}
                          </Typography>
                          <IconButton
                            aria-label="delete"
                            className={classes.iconButton}
                            onClick={() => {
                              deleteReminder(reminder);
                            }}
                          >
                            <DeleteForever className={classes.deleteIcon} />
                          </IconButton>
                        </div>
                        <DateTimePicker
                          value={DateTime.fromMillis(reminder)}
                          onChange={(event) => changeReminder(reminder, event)}
                          showTodayButton
                          disablePast
                          format="t"
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Fragment>
              );
            })}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  data-cy="default-reminders-button"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={defaultReminders}
                  className={classes.submit}
                >
                  Set Default Reminders
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  data-cy="default-reminders-button"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={clearReminders}
                  className={classes.submit}
                >
                  Clear All Reminders
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          open={errorAlert}
          autoHideDuration={6000}
          onClose={closeAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            data-cy="errorAlert"
            severity="error"
            variant="filled"
            onClose={closeAlert}
          >
            {errorAlert}
          </MuiAlert>
        </Snackbar>
        <Dialog open={openDialog} onClose={closeDialog}>
          <DateTimePicker
            value={newReminder}
            onChange={(event) => {
              setNewReminder(event);
            }}
            showTodayButton
            disablePast
            variant="static"
          />
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
            className={classes.dialogButtons}
          >
            <Grid item>
              <Button
                data-cy="close-dialog-button"
                variant="contained"
                color="primary"
                onClick={() => {
                  addReminder();
                }}
                className={classes.submit}
              >
                Create Reminder
              </Button>
            </Grid>
            <Grid item>
              <Button
                data-cy="close-dialog-button"
                variant="contained"
                color="primary"
                onClick={closeDialog}
                className={classes.submit}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </MuiPickersUtilsProvider>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  bodyText: {
    "& p:not(:first-child)": {
      margin: "16px 0px",
    },
  },
  deleteIcon: {
    color: "#FF0000",
  },
  editIcon: {
    color: "#00FF00",
  },
  reminderDiv: {
    marginTop: theme.spacing(2),
  },
  controls: {
    display: "flex",
    marginBottom: theme.spacing(3),
  },
  dialogButtons: {
    marginBottom: theme.spacing(2),
  },
  typo: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
});

export default withStyles(styles)(Reminder);
