// Remove the next three lines when the content on this page is restored.
// import React, { useContext } from 'react'
// import { Typography, Paper, Avatar} from '@material-ui/core'
// import { withRouter,Redirect} from 'react-router-dom'
// Uncomment the next three lines when the content on this page is restored
import React, { useState, useContext } from "react";
import { Typography, Paper, Avatar, Button } from "@material-ui/core";
import { Link, withRouter, Redirect } from "react-router-dom";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import withStyles from "@material-ui/core/styles/withStyles";
import firebase from "../../config/firebase";

// import app from '../config/firebase'
import { AuthContext } from "../../providers/Auth";
import MuiAlert from "@material-ui/lab/Alert";

function RegisterFAQ(props) {
  const { classes } = props;
  const { currentUser } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [errorAlertBool, setErrorAlertBool] = useState(false);
  const [loading, setLoading] = useState(false);

  if (currentUser) {
    return <Redirect to="/daily-lesson" />;
  }

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          Register FAQ
        </Typography>
        <Typography variant="h6" gutterBottom>
          Do I really need to register?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Yes! and No... The Badass ACIM app does require registration in order
          to work correctly. This is because the app is more than just an
          'electronic book'; in that it has options to set reminders; sync
          across devices; set favourites; etc... and needs a secure database
          squirreled away in the cloud. By registering, we are able to create a
          unique account for your in this database and ensure that everything
          works as intended.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you would prefer not to register then the app probably isn't the
          best fit for you. But worry not, you CAN STILL DO the course... just{" "}
          <Link to={"/about-pam"}>purchase Pam's book</Link> and you are away!
        </Typography>
        <Typography variant="h6" gutterBottom>
          Can I cancel my account?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Absolutely!! On the User Settings page there is an account
          cancellation link which will permanently, and irrevocably cancel your
          account in a trice. No questions. No complicated procedures. Just a
          simple 'No Thank You!'; and you're done.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Will I get spam from registering?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Hell No! Your personal details are ONLY collected to provide you with
          the specific app services and will NEVER be sold or otherwise
          mistreated. If you want to read through all the details then please
          see our <Link to={"/legals/privacy"}>privacy policy here</Link>, which
          has all the legal mumbo-jumbo. But the gist is that your details are
          yours; and we totally respect that.
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          component={Link}
          to="/register"
          className={classes.submit}
        >
          Go back to Register
        </Button>
      </Paper>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  hintText: {
    color: "darkgrey",
  },
});

export default withRouter(withStyles(styles)(RegisterFAQ));
