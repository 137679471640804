import React, { useContext, useState } from "react";
import {
  Typography,
  Paper,
  Avatar,
  Button,
  FormControl,
  Input,
  InputLabel,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link, Redirect } from "react-router-dom";
import { AuthContext } from "../providers/Auth";
import app from "../config/firebase";

function ForgotPassword(props) {
  const { classes } = props;
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [progressIndicator, setProgressIndicator] = useState(false);

  async function ResetPassword() {
    setProgressIndicator(true);
    await app
      .auth()
      .sendPasswordResetEmail(email.trim())
      .then((success) => {
        setProgressIndicator(false);
        setSuccessAlert(true);
        setTimeout(() => {
          props.history.replace("/login");
        }, 3000);
        //
      })
      .catch((error) => {
        setProgressIndicator(false);
        setErrorAlert(true);
        console.error(error);
      });
  }

  const closeAlert = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
  };

  if (currentUser) {
    return <Redirect to="/daily-lesson" />;
  }

  if (progressIndicator) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress
          size={200}
          thickness={1.5}
          className={classes.CircularProgress}
        />
      </div>
    );
  }

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Your Password
        </Typography>
        <Typography component="p" variant="body1" className={classes.textLink}>
          Please enter your registered email address below.
        </Typography>
        <form
          className={classes.form}
          onSubmit={(e) => e.preventDefault() && false}
        >
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              id="email"
              name="email"
              autoComplete="off"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={ResetPassword}
            className={classes.submit}
          >
            Reset Password
          </Button>
          <Typography
            component="p"
            variant="body1"
            className={classes.textLink}
          >
            <Link to={"/login"}>Go Back to Login.</Link>
          </Typography>
        </form>
      </Paper>
      <Snackbar
        open={successAlert}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          data-cy="successAlert"
          severity="success"
          variant="filled"
          onClose={closeAlert}
        >
          Success! Please follow the directions in the email.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={errorAlert}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          data-cy="errorAlert"
          severity="error"
          variant="filled"
          onClose={closeAlert}
        >
          Hmmm... We can find your email address in the Akashic Records. Is it
          spelt crroectly?
        </MuiAlert>
      </Snackbar>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  CircularProgress: {
    marginTop: "15%",
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  textLink: {
    marginTop: theme.spacing(3),
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

export default withStyles(styles)(ForgotPassword);
