import React, { useState } from "react";
import {
  Typography,
  Paper,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Button,
  Dialog,
  DialogActions,
  Divider,
  DialogTitle,
} from "@material-ui/core";

import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import SupportContact from "./SupportContact";
import HelpIcon from "@material-ui/icons/Help";
import PWAInstallation from "./supportSnippets/PWAInstallation";
import ChangeLesson from "./supportSnippets/ChangeLesson";

function SupportHome(props) {
  const { classes } = props;
  const [pwaInstall, setPwaInstall] = useState(false);
  const [resetLesson, setResetLesson] = useState(false);

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Typography variant="h6" data-cy="header">
          App Help & Support
        </Typography>
        <Typography
          variant="body1"
          className={classes.bodyText}
          data-cy="body2"
        >
          We have tried to make the app as intuitive as possible so there
          wouldn't be much need for support notes. Of course a Developer's view
          of what is obvious is different from a User's view... ;-) So please
          find below a list of Frequently Asked Questions (FAQ). If these still
          don't meet your needs, please reach out to us using the support form.
        </Typography>
        <List>
          <ListItem>
            <Button onClick={() => setPwaInstall(true)} data-cy={"pwainstall"}>
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText>How can I install this App?</ListItemText>
            </Button>
          </ListItem>
          <ListItem>
            <Button
              onClick={() => setResetLesson(true)}
              data-cy={"resetLesson"}
            >
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText>How do I change my lesson number?</ListItemText>
            </Button>
          </ListItem>
        </List>
        <Typography variant="h6" data-cy="header">
          Contact Support
        </Typography>
        <Typography
          variant="body1"
          className={classes.bodyText}
          data-cy="body1"
        >
          Found a bug? Perhaps the app would be better if it had a specific
          feature? FAQ's didn't help? The form below will allow you to reach out
          for assistance. Simply fill out the form with as much detail as
          possible and it'll create a ticket in our system.
        </Typography>
        <SupportContact />
      </Paper>
      <Dialog open={pwaInstall} onClose={() => setPwaInstall(false)}>
        <PWAInstallation />
        <DialogActions>
          <Button
            onClick={() => setPwaInstall(false)}
            color={"primary"}
            variant={"contained"}
            data-cy={"close-pwa"}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={resetLesson} onClose={() => setResetLesson(false)}>
        <ChangeLesson />
        <DialogActions>
          <Button
            onClick={() => setResetLesson(false)}
            color={"primary"}
            variant={"contained"}
            data-cy={"close-reset"}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  bodyText: {
    "& p:not(:first-child)": {
      margin: "16px 0px",
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
});

export default withRouter(withStyles(styles)(SupportHome));
