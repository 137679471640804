import React, { useContext, useEffect, useState } from 'react'
import { Typography, Paper, CircularProgress, Card, CardContent, Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../providers/Auth'
import firebase from '../config/firebase'
import {lessonData} from "../config/lessons"



function LessonFavourites(props) {
	const { classes } = props
	const {currentUser} = useContext(AuthContext)
	const [userData, setUserData] = useState(null)

	const db = firebase.firestore()

	useEffect(() => {
		let unsubscribe = null;

		if(currentUser !== null) {
			unsubscribe = db.collection(`users`).doc(`${currentUser.uid}`).onSnapshot(doc => {
				setUserData(doc.data())
				console.log(doc.data())
			})
		} else {
			props.history.replace('/login')
		}
		return () => unsubscribe()
	},[currentUser, db, props.history])

	if(userData === null) {
		return (
			<div style={{display: "flex", justifyContent: 'center'}}>
				<CircularProgress size={200} thickness={1.5} className={classes.CircularProgress}/>
			</div>
			)
	}

	return (
		<main className={classes.main}>
			<Paper className={classes.paper}>
				<Typography component="h1" variant="h5">
					Lesson Favourites
				</Typography>
				<Typography component="p" variant="body1">
					{userData.favouriteLessons.length === 0 ? "You don't seem to have any favourite lessons yet. Go to the daily lesson, or lesson history, pages to mark a particular lesson as a favourite." :
					"Below you will find a list of all your favourite lessons. Clicking any lesson will give you an opportunity to review that lesson."}
				</Typography>
				<Grid container spacing={2}>


				{userData.favouriteLessons.map((lessonNo) => (
					<Grid item xs={12} sm={6} md={4}>
					<Card variant="outlined" className={classes.completedCard}>
						<CardContent>
						<Typography className={classes.title} color="textSecondary" gutterBottom>
          					{`Lesson No. ${lessonNo}`}
        				</Typography>
        				<Typography variant="h6" component="h6">
          					{lessonData[lessonNo].title.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})}
        				</Typography>
						</CardContent>
					</Card>
					</Grid>
	))}</Grid>
			</Paper>
		</main>
	)
}

const styles = theme => ({
	main: {
		width: '90%',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	CircularProgress: {
		marginTop: "15%",
	},
	quoteAuthor: {
		'align-self': 'flex-end'
	},
	bodyText: {
		'& p': {
			margin: '16px 0px'
		}
	},
	completedCard: {
		// height: "20vh"
	},
	paper: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
	},
	title: {
		fontSize: 14,
	},
})

export default withRouter(withStyles(styles)(LessonFavourites))