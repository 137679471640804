import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Typography,
  Paper,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../providers/Auth";
import firebase from "../../config/firebase";
import { lessonData } from "../../config/lessons";

function LessonHistory(props) {
  const { classes } = props;
  const { currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [detailLessonNo, setDetailLessonNo] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);

  const db = firebase.firestore();

  useEffect(() => {
    let unsubscribe = null;

    if (currentUser !== null) {
      unsubscribe = db
        .collection(`users`)
        .doc(`${currentUser.uid}`)
        .onSnapshot((doc) => {
          setUserData(doc.data());
        });
    } else {
      props.history.replace("/login");
    }
    return () => unsubscribe();
  }, [currentUser, db, props.history]);

  const truncate = (text, length, useWordBoundary) => {
    if (text.length <= length) {
      return text;
    }
    const substring = text.substr(0, length - 1);
    return (
      (useWordBoundary
        ? substring.substr(0, substring.lastIndexOf(" "))
        : substring) + "&hellip;"
    );
  };

  const readMore = (lessonNo) => {
    setDetailLessonNo(lessonNo);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setDetailLessonNo(1);
  };

  if (userData === null) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress
          size={200}
          thickness={1.5}
          className={classes.CircularProgress}
        />
      </div>
    );
  }

  if (
    userData.subscription.status === "canceled" ||
    userData.subscription.status === "unpaid" ||
    userData.subscription.status === "incomplete_expired"
  ) {
    return (
      <Fragment>
        <main className={classes.main}>
          <Paper className={classes.paper}>
            <Typography
              variant="h6"
              className={classes.headers}
              data-cy="header"
              gutterBottom
            >
              Lesson History
            </Typography>
            <Typography
              variant="body1"
              className={classes.bodyText}
              data-cy="body1"
              gutterBottom
            >
              Ummm.... unfortunately the history functionality requires a valid
              subscription which we seem to have difficultly locating in your
              account.
            </Typography>
            <Typography
              variant="body1"
              className={classes.bodyText}
              data-cy="body2"
            >
              If you would like to continue using Badass ACIM then please go to
              the subscription tab in the settings area.
            </Typography>
          </Paper>
        </main>
      </Fragment>
    );
  }

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom data-cy="header">
          Lesson History
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={classes.bodyText}
          gutterBottom
          data-cy="body1"
        >
          {userData.completedLessons.length === 0
            ? "You don't seem to have completed any lessons yet. Go to the daily lesson page to complete your first lesson."
            : "Below you will find a list of all your completed lessons. Clicking 'READ MORE' will give you an opportunity to review that lesson."}
        </Typography>
        <Grid container spacing={2}>
          {userData.completedLessons.map((lessonNo) => (
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={4} className={classes.cardPaper}>
                <Card
                  variant="outlined"
                  className={classes.completedCard}
                  data-cy="history-card"
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <CardContent className={classes.cardContent}>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          {`Lesson No. ${lessonNo}`}
                        </Typography>
                        <Typography variant="h6" component="h6">
                          {lessonData[lessonNo - 1].title.replace(
                            /\w\S*/g,
                            function (txt) {
                              return (
                                txt.charAt(0).toUpperCase() +
                                txt.substr(1).toLowerCase()
                              );
                            }
                          )}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.bodyText}
                          dangerouslySetInnerHTML={{
                            __html: truncate(
                              lessonData[lessonNo - 1].bodyText,
                              250 - lessonData[lessonNo - 1].title.length,
                              true
                            ),
                          }}
                        ></Typography>
                      </CardContent>
                    </Grid>
                    <Grid item xs={12}>
                      <CardActions className={classes.actionButton}>
                        <Button
                          type="button"
                          data-cy="read-more-button"
                          size="small"
                          color="primary"
                          onClick={() => {
                            readMore(lessonNo);
                          }}
                          className={classes.submit}
                        >
                          Read More...
                        </Button>
                      </CardActions>
                    </Grid>
                  </Grid>
                </Card>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Dialog open={modalOpen} onClose={closeModal}>
        <DialogTitle>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" data-cy="lessonNo">
                Lesson No. {detailLessonNo}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: lessonData[detailLessonNo - 1].title.toUpperCase(),
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="caption"
            display="block"
            dangerouslySetInnerHTML={{
              __html: lessonData[detailLessonNo - 1].quoteText,
            }}
          />
          <Typography
            variant="caption"
            className={classes.quoteAuthor}
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: lessonData[detailLessonNo - 1].quoteAuthor,
            }}
          />
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: lessonData[detailLessonNo - 1].bodyText,
            }}
            className={classes.bodyText}
            paragraph={true}
            data-cy="bodyText"
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            data-cy="close-button"
            variant="contained"
            color="primary"
            onClick={closeModal}
            className={classes.submit}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  CircularProgress: {
    marginTop: "15%",
  },
  quoteAuthor: {
    "align-self": "flex-end",
  },
  bodyText: {
    "& p": {
      margin: "16px 0px",
    },
  },
  cardPaper: {
    marginTop: theme.spacing(2),
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  completedCard: {
    display: "flex",
    flexDirection: "column",
    flex: "1 0 auto",
    position: "relative",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: "1 0 auto",
    "justify-content": "center",
  },
  actionButton: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    position: "absolute",
    bottom: "0",
    right: "0",
    // flex: "1 0 auto"
  },
  submit: {
    alignSelf: "flex-end",
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  title: {
    fontSize: 14,
  },
});

export default withRouter(withStyles(styles)(LessonHistory));
