import React from 'react'
import {Typography, Paper, Grid, Button} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import {Link} from "react-router-dom";



function SubscriptionSuccess(props) {
	const { classes } = props



	return (
		<main className={classes.main}>
			<Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          WooHoo! You are Subscribed!
          </Typography>
          <Typography variant="body1" gutterBottom>
            Great to have you aboard, and thank you for subscribing to Badass ACIM. If there is anything we can do to make your experience with the app better, please feel free to reach out to us via the support link. Please note that you can alter your subscription details at any time on the subscriptions tab in the settings area.
          </Typography>
          <Typography variant="body1" gutterBottom>
              In the meantime, please feel free to follow the link below to get back to Today's Lesson.
          </Typography>
            <Button
                type="button"
                data-cy="payment-redirect-button"
                fullWidth
                component={Link}
                to="/daily-lesson/text"
                variant="contained"
                color="primary"
                className={classes.submit}>
              Go to Today's Lesson
            </Button>
          </Grid>
        </Grid>
			</Paper>
		</main>
	)
}

const styles = theme => ({
	main: {
		width: '90%',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
		[theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  media: {
    height: "60vh",
  }
})

export default withStyles(styles)(SubscriptionSuccess)