import React from 'react'
import {Typography, Paper, Grid, Button} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

import {Link} from "react-router-dom";



function SubscriptionCancel(props) {
	const { classes } = props



	return (
		<main className={classes.main}>
			<Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
          Hmmm... that didn't seem to work!
          </Typography>
          <Typography variant="body1" gutterBottom>
            No problems. You can try again any time you like from the settings area.
          </Typography>
          <Typography variant="body1" gutterBottom>
            If you cancelled your subscription due to technical difficulties, then please reach out to us (using the support link in the side menu) so we can assist. In the meantime, please follow the link below to get back to Today's Lesson.
          </Typography>
            <Button
                type="button"
                data-cy="payment-redirect-button"
                fullWidth
                component={Link}
                to="/daily-lesson/text"
                variant="contained"
                color="primary"
                className={classes.submit}>
              Go to Today's Lesson
            </Button>
          </Grid>
        </Grid>
			</Paper>
		</main>
	)
}

const styles = theme => ({
	main: {
		width: '90%',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
		[theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  media: {
    height: "60vh",
  }
})

export default withStyles(styles)(SubscriptionCancel)