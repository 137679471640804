import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import withStyles from "@material-ui/core/styles/withStyles";
import { AuthContext } from "../../providers/Auth";
import firebase from "../../config/firebase";
import DateTime from "luxon/src/datetime";

import SubscriptionOptions from "./SubscriptionOptions";
import Dashboard from "./Dashboard";

function Subscribe(props) {
  const { classes, userData } = props;
  const { currentUser } = useContext(AuthContext);
  const [alert, setAlert] = useState({ text: "", severity: "", open: false });

  const auth = firebase.auth();

  const verifyEmail = () => {
    if (!currentUser.email.startsWith("test_")) {
      auth.currentUser
        .sendEmailVerification()
        .then(() => {
          setTimeout(() => {
            auth.signOut();
          }, 10000);
          setAlert({
            text:
              "Awesome! We have just sent you an email. Please click the link in the email to verify your address. You will need to sign back in after verification.",
            severity: "success",
            open: true,
          });
        })
        .catch((error) => {
          setAlert({
            text: "Dang! An error occurred. Please try again later.",
            severity: "error",
            open: true,
          });
        });
    } else {
      console.log("Test User Only");
      setTimeout(() => {
        auth.signOut();
      }, 10000);
      setAlert({
        text:
          "Awesome! We have just sent you an email. Please click the link in the email to verify your address. You will need to sign back in after verification.",
        severity: "success",
        open: true,
      });
    }
  };

  const pageDetail = () => {
    if (!currentUser.emailVerified) {
      return (
        <Fragment>
          <Typography
            variant="body1"
            className={classes.bodyText}
            data-cy="verifyEmailText"
          >
            You must verify your email address before accessing subscription
            options. Please verify your email address using the button below.
          </Typography>
          <Button
            data-cy="verify-email-button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => verifyEmail()}
            className={classes.submit}
          >
            Verify Email
          </Button>
        </Fragment>
      );
    } else if (
      userData.subscription.status === "past_due" ||
      userData.subscription.status === "incomplete"
    ) {
      return (
        <Fragment>
          <p data-cy="body1">
            Your last payment failed. But don't worry, you are still subscribed
            on the {userData.subscription.subType} subscription plan{" "}
            <strong>BUT</strong> if you don't take action soon then your access
            to Badass ACIM may be limited.
          </p>
          <p data-cy="body2">
            If you would like to update your payment method then please use the
            button below.
          </p>
          <Dashboard userData={userData} />
        </Fragment>
      );
    } else if (userData.subscription.status === "trialing") {
      return (
        <Fragment>
          <p>We hope you are enjoying using Badass ACIM?</p>
          <p data-cy="body1">
            You are currently on the trial subscription plan which is valid
            until{" "}
            <strong>
              {DateTime.fromSeconds(
                userData.subscription.current_period_end
              ).toFormat("dd LLL yyyy")}
            </strong>
            . Your subscription will automatically expire{" "}
            <strong>unless</strong> you add a subscription prior to this time.
          </p>
          <p data-cy="body2">
            If you would like to subscribe to Badass ACIM then please choose an
            option below.
          </p>
          <SubscriptionOptions userData={userData} />
        </Fragment>
      );
    } else if (
      userData.subscription.status === "canceled" ||
      userData.subscription.status === "unpaid" ||
      userData.subscription.status === "incomplete_expired"
    ) {
      return (
        <Fragment>
          <p data-cy="body1">
            Your subscription has ended! Was it something we said?!?
          </p>
          <p data-cy="body2">
            If you would like to continue using Badass ACIM then you will need
            to choose a subscription below. If technical difficulties were
            causing you grief then do please get in touch so we can assist. We
            are always here to help in any way we can.
          </p>
          <SubscriptionOptions userData={userData} />
        </Fragment>
      );
    } else if (userData.subscription.status === "lifetime") {
      return (
        <Fragment>
          <p data-cy="body1">We hope you are enjoying using Badass ACIM?</p>
          <p data-cy="body2">
            You are currently on lifetime subscription plan which is currently
            valid until... well forever!. Your subscription will never
            automatically expire but you can cancel it at any time by deleting
            your account on the <Link to="/settings/account">Account Tab</Link>.
          </p>
        </Fragment>
      );
    } else if (userData.subscription.status === "active") {
      return (
        <Fragment>
          <p data-cy="body1">We hope you are enjoying using Badass ACIM?</p>
          <p data-cy="body2">
            You currently have an active subscription on the{" "}
            {userData.subscription.subType} plan which is valid until{" "}
            <strong>
              {DateTime.fromSeconds(
                userData.subscription.current_period_end
              ).toFormat("dd LLL yyyy")}
            </strong>
            . Your subscription will automatically renew at this time.
          </p>
          <p>
            If you would like to manage your subscription or payment method then
            please follow the below link to the dashboard.
          </p>
          <Dashboard userData={userData} />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <p>Your subscription has ended! Was it something we said?!?</p>
          <p>
            If you would like to continue using Badass ACIM then you will need
            to choose a subscription below. If technical difficulties were
            causing you grief then do please get in touch so we can assist. We
            are always here to help in any way we can.
          </p>
          <SubscriptionOptions userData={userData} />
        </Fragment>
      );
    }
  };

  const closeAlert = () => {
    setAlert({ text: "", severity: "", open: false });
  };

  return (
    <Fragment>
      <Typography variant="h6" className={classes.headers} data-cy="header">
        Subscription Status
      </Typography>
      <Typography variant="body1" className={classes.bodyText}>
        {pageDetail()}
      </Typography>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          data-cy="settingsAlert"
          severity={alert.severity}
          variant="filled"
          onClose={closeAlert}
        >
          {alert.text}
        </MuiAlert>
      </Snackbar>
    </Fragment>
  );
}

const styles = (theme) => ({
  main: {
    width: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  headers: {
    marginTop: theme.spacing(3),
  },
  bodyText: {
    marginTop: theme.spacing(1),
    "& p:not(:first-child)": {
      margin: "16px 0px",
    },
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  stripePayLogo: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    marginTop: theme.spacing(1),
  },
});

export default withStyles(styles)(Subscribe);
