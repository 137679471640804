import React, { Fragment } from "react";
import { Typography, Paper, Grid, Button, Box } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import pamImage from "../assets/welcome-photo.jpg";
import lesson1Mockup from "../assets/lesson1-mockup.png";
import { Link } from "react-router-dom";

import MenuBookIcon from "@material-ui/icons/MenuBook";
import HistoryIcon from "@material-ui/icons/History";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AddAlertIcon from "@material-ui/icons/AddAlert";

function HomePage(props) {
  const { classes } = props;

  return (
    <Fragment>
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  src={lesson1Mockup}
                  alt={"Phone Mockup"}
                  className={classes.mockupImage}
                  data-cy="phoneMockup"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} className={classes.headerTextContainer}>
              <Typography
                variant={"h3"}
                className={classes.splashText}
                align={"center"}
                data-cy="header1"
              >
                Change your Consciousness
              </Typography>
              <Typography
                variant={"h3"}
                className={classes.splashText}
                align={"center"}
                data-cy="header2"
              >
                Change the World
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className={classes.bodyText}
                data-cy="body1"
              >
                <p>
                  For 50 years, <i>A Course in Miracles</i> has offered a proven
                  method for rewiring our entire belief system. From madness to
                  miracles. From scarcity to abundance. From fear to love.
                </p>
                <p>
                  The only problem? It’s 1333 pages, unwieldy and reads like a
                  bookshelf assembly manual.
                </p>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant={"h4"}
                className={classes.appText}
                gutterBottom
              >
                This interactive app to the rescue!
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
              <Typography variant="body1" className={classes.bodyText}>
                <p>
                  A modern-day re-haul of ACIM’s 365-day workbook, Course in
                  Miracles for Badasses is simple to use, easy to understand and
                  guaranteed to radically reframe all ridiculous, outdated
                  ideologies that currently run your life.
                </p>
                <p>
                  Written by Pam Grout, the irreverent international bestselling
                  author of E-Squared, this app has the power to release us from
                  the wildly distorted belief in isolation, deprivation and
                  lack. It reminds us, again and again (until it's just how we
                  roll) that we can just as easily ask for love, happiness and
                  peace.{" "}
                </p>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  id="pamImage"
                  src="../assets/welcome-photo.jpg"
                  alt=""
                  className={classes.pamImage}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant={"h4"}
                className={classes.appText}
                gutterBottom
              >
                Features
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <MenuBookIcon className={classes.featuresIcons}></MenuBookIcon>
              </Box>
              <Typography variant={"h6"} align={"center"} data-cy="features1">
                Interactive Daily Lessons
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <AddAlertIcon className={classes.featuresIcons}></AddAlertIcon>
              </Box>
              <Typography variant={"h6"} align={"center"}>
                Customisable & Automagic Reminders
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <HistoryIcon className={classes.featuresIcons}></HistoryIcon>
              </Box>
              <Typography variant={"h6"} align={"center"}>
                Browsable History
              </Typography>
            </Grid>
            {/*<Grid item xs={12} sm={6}>*/}
            {/*	<Box*/}
            {/*		display={"flex"}*/}
            {/*		justifyContent={"center"}*/}
            {/*		alignItems={"center"}*/}
            {/*	>*/}
            {/*		<FavoriteIcon className={classes.featuresIcons}></FavoriteIcon>*/}
            {/*	</Box>*/}
            {/*	<Typography variant={"h6"} align={"center"}>Favourite Lessons</Typography>*/}
            {/*</Grid>*/}
          </Grid>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Button
                variant={"contained"}
                type="button"
                fullWidth
                data-cy="try-it-button"
                color="primary"
                component={Link}
                to="/register"
                className={classes.submit}
              >
                Try It for Free Today!
              </Button>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  variant={"caption"}
                  align={"center"}
                  className={classes.noCreditCard}
                  data-cy="noCreditCard"
                >
                  No Credit Card Required
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Paper>
      </main>
    </Fragment>
  );
}

const styles = (theme) => ({
  main: {
    width: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  bodyText: {
    "& p": {
      margin: "16px 0px",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  mockupImage: {
    width: "100%",
  },
  headerTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  splashText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    // fontWeight: "bold"
    color: theme.palette.secondary.dark,
  },
  pamImage: {
    width: "80%",
  },
  noCreditCard: {
    marginTop: theme.spacing(1),
  },
  featuresIcons: {
    marginRight: theme.spacing(1),
    fontSize: "6vh",
  },
});

export default withStyles(styles)(HomePage);
