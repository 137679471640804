import React, { useEffect, useContext, useState, Fragment } from "react";
import {
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  AppBar,
  FormControlLabel,
  Switch,
  Snackbar,
  Link,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import firebase from "../../config/firebase";
import { AuthContext } from "../../providers/Auth";
import LuxonUtils from "@date-io/luxon";
import { DateTime } from "luxon";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { useSwipeable } from "react-swipeable";

import LessonSettings from "./LessonSettings";
import UserSettings from "./UserSettings";
import Subscribe from "./Subscribe";

function Settings(props) {
  const { classes, match, history } = props;
  const { params } = match;
  const { tab } = params;
  const { currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  // const [permissionText, setPermissionText] = useState("")
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ text: "", severity: "", open: false });
  const [remindersEnabled, setRemindersEnabled] = useState(false);

  const db = firebase.firestore();
  const publicVapidKey =
    "BNakKC_DOeJ3w-o9BOnAcYb8zFV3lwRgEA6_Oa9nJ6lCYledSiOijRAzA6vYoEHRGCviEZw7itk9K2kuTvwl_gg";

  useEffect(() => {
    let unsubscribe = null;

    if (currentUser !== null) {
      const db = firebase.firestore();
      unsubscribe = db
        .collection("users")
        .doc(`${currentUser.uid}`)
        .onSnapshot((doc) => {
          checkDeviceStatus(doc.data().messaging.device_ids);
          setStartTime(
            DateTime.fromFormat(
              `${doc.data().messaging.active_period.start}`,
              "H"
            )
          );
          setEndTime(
            DateTime.fromFormat(
              `${doc.data().messaging.active_period.end}`,
              "H"
            )
          );
          setUserData(doc.data());
        });
    } else {
      props.history.replace("/login");
    }
    return () => unsubscribe();
  }, [currentUser, props.history]);

  const updateReminderDefault = async (event) => {
    db.collection("users").doc(`${currentUser.uid}`).update({
      "messaging.default": event.target.checked,
    });
  };

  const handleTimeChange = async (date, operation) => {
    if (operation === "start") {
      setStartTime(date);
      db.collection("users")
        .doc(`${currentUser.uid}`)
        .update({
          "messaging.active_period.start": date.toFormat("H"),
        })
        .then(() => {
          setAlert({
            text: "Start time has been updated",
            severity: "success",
            open: true,
          });
        });
    } else if (operation === "end") {
      setEndTime(date);
      db.collection("users")
        .doc(`${currentUser.uid}`)
        .update({
          "messaging.active_period.end": date.toFormat("H"),
        })
        .then(() => {
          setAlert({
            text: "End time has been updated",
            severity: "success",
            open: true,
          });
        });
    } else {
      console.error("Unrecognised value passed into Time Change Function");
      setAlert({
        text: "Dang! An error occurred. Please try again later.",
        severity: "error",
        open: true,
      });
    }
  };

  const checkDeviceStatus = async (deviceArray) => {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        let deviceToken = "";
        if (localStorage.getItem("deviceToken") !== undefined) {
          deviceToken = localStorage.getItem("deviceToken");
          console.log(deviceToken);
          console.log(deviceArray);
        }
        // const deviceToken = await messaging.getToken({vapidKey: publicVapidKey});
        setRemindersEnabled(
          deviceArray.some((device) => {
            return device.device_id === deviceToken;
          })
        );
      }
    }
  };

  const autoMagicText = () => {
    if (!("serviceWorker" in navigator) && !("PushManager" in window)) {
      return (
        <Typography variant="body1" className={classes.bodyText}>
          I'm sorry; but as push notifications aren't currently supported by
          your operating system/browser, you aren't able to enable Automagic
          Reminders.
        </Typography>
      );
    } else {
      return (
        <Fragment>
          <Typography variant="body1" className={classes.bodyText}>
            Enabling automagic reminders will allow the app to automatically
            schedule your daily reminders based on the recommended daily
            repetitions. You can override these on each lesson.
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={userData.messaging.default}
                onChange={updateReminderDefault}
                name="Default-Reminders"
                data-cy="automagic-swtich"
                color="primary"
              />
            }
            label={
              userData.messaging.default
                ? "Automagic Reminders Enabled"
                : "Enable Automagic Reminders"
            }
          />
          {!userData.messaging.default ? null : (
            <Fragment>
              <Typography variant="body1" className={classes.bodyText}>
                Since you have enabled Automagic Reminders, you may like to set
                an 'active' period. The active period is the start & end times
                for automagically setting reminders so that you will only
                recieve reminders between the hours noted below.
              </Typography>
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <Grid container justify="space-evenly">
                  <KeyboardTimePicker
                    margin="normal"
                    id="start-time-picker"
                    label="Start Time"
                    value={startTime}
                    minutesStep={60}
                    onChange={(event) => handleTimeChange(event, "start")}
                    KeyboardButtonProps={{
                      "aria-label": "change start time",
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="end-time-picker"
                    label="End Time"
                    value={endTime}
                    minutesStep={60}
                    onChange={(event) => handleTimeChange(event, "end")}
                    KeyboardButtonProps={{
                      "aria-label": "change end time",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Fragment>
          )}
        </Fragment>
      );
    }
  };

  const checkNotifyPermission = () => {
    if (!("serviceWorker" in navigator) && !("PushManager" in window)) {
      return (
        <Fragment>
          <Typography variant="body1" className={classes.bodyText}>
            I'm sorry; but the operating system and/or browser combination you
            are using does not support the push-reminder functionality. We are
            currently working on email reminder options in order to more fully
            support your system and intend that to be available by 18 December
            2020. There will also be native apps available in the near future.
            Apologies for the inconvenience.
          </Typography>
        </Fragment>
      );
    } else if (!("Notification" in window)) {
      console.log("This browser does not support desktop notifications.");
      return (
        <Fragment>
          <Typography variant="body1" className={classes.bodyText}>
            Unfortunately the browser you are currently using does not support
            notifications. For the best experience, please consider switching to{" "}
            <a
              href="https://www.mozilla.org/en-US/firefox/new/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Firefox
            </a>{" "}
            or{" "}
            <a
              href="https://www.google.com.au/chrome/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chrome
            </a>
            .
          </Typography>
        </Fragment>
      );
    } else if (
      Notification.permission === "granted" &&
      userData.messaging.permission &&
      remindersEnabled
    ) {
      return (
        <Fragment>
          <Typography variant="body1" className={classes.bodyText}>
            Awesome! You are all set up for reminders. If you would like to
            revoke this permission for this device please use the button below.
          </Typography>
          <Button
            data-cy="notification-permission-button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => changeMessagingPermission("revoke")}
            className={classes.submit}
          >
            Thanks, but please disable reminders on this device
          </Button>
        </Fragment>
      );
    } else if (
      Notification.permission === "granted" &&
      userData.messaging.permission &&
      !remindersEnabled
    ) {
      return (
        <Fragment>
          <Typography variant="body1" className={classes.bodyText}>
            Ah! It appears that someone has revoked the reminder functionality
            for this device. Did you want to re-establish reminders on this
            device?
          </Typography>
          <Button
            data-cy="notification-permission-button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => changeMessagingPermission("grant")}
            className={classes.submit}
          >
            Hell Yeah! Gimme reminders...
          </Button>
        </Fragment>
      );
    } else if (
      Notification.permission === "granted" &&
      !userData.messaging.permission
    ) {
      return (
        <Fragment>
          <Typography variant="body1" className={classes.bodyText}>
            Ah! It appears that someone has revoked the reminder functionality
            for your account. Did you want to re-establish reminders for this
            device?
          </Typography>
          <Button
            data-cy="notification-permission-button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => changeMessagingPermission("grant")}
            className={classes.submit}
          >
            Hell Yeah! Gimme reminders...
          </Button>
        </Fragment>
      );
    } else if (
      Notification.permission === "default" &&
      userData.messaging.permission
    ) {
      return (
        <Fragment>
          <Typography variant="body1" className={classes.bodyText}>
            Reminder permissions must be allowed on a device by device basis...
            one of those rules enforced by the "G" (ie, Google). ;-) You have
            previously enabled permissions on another device, did you want to
            enable them here too?
          </Typography>
          <Button
            data-cy="notification-permission-button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => changeMessagingPermission("grant")}
            className={classes.submit}
          >
            Hell Yeah! Gimme reminders...
          </Button>
        </Fragment>
      );
    } else if (
      Notification.permission === "default" &&
      !userData.messaging.permission
    ) {
      return (
        <Fragment>
          <Typography variant="body1" className={classes.bodyText}>
            To allow reminders you will need to grant permission to receive
            reminders by clicking the button below. You will also need to grant
            permission for notifications in the popup window that follows.
          </Typography>
          <Button
            data-cy="notification-permission-button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => changeMessagingPermission("grant")}
            className={classes.submit}
          >
            Hell Yeah! Gimme reminders...
          </Button>
        </Fragment>
      );
    } else if (Notification.permission === "denied") {
      return (
        <Fragment>
          <Typography variant="body1" className={classes.bodyText}>
            Someone has previously denied reminders on this device which means
            that we are unable to deliver reminders to this device. If you would
            like to change this then you will need to update the settings in
            your browser manually... unfortunately the browsers won't allow us
            to assist you with this once permission has been denied.
          </Typography>
        </Fragment>
      );
    }
  };

  const changeMessagingPermission = async (operation) => {
    setLoading(true);
    if (typeof Storage !== "undefined") {
      if (operation === "grant") {
        firebase
          .messaging()
          .getToken({ vapidKey: publicVapidKey })
          .then(async (token) => {
            localStorage.setItem("deviceToken", `${token}`);
            let tempDeviceArray = [...userData.messaging.device_ids];
            const deviceExists = tempDeviceArray.some((device) => {
              return device.device_id === token;
            });
            if (!deviceExists) {
              const unixDate = Date.now() / 1000;
              // check array for existing token prior to creating new object.
              const newDevice = {
                device_id: token,
                date_added: unixDate,
                date_last: unixDate,
              };
              tempDeviceArray.push(newDevice);
              await db
                .collection("users")
                .doc(`${currentUser.uid}`)
                .update({
                  "messaging.permission": true,
                  "messaging.device_ids": tempDeviceArray,
                })
                .then(() => {
                  setAlert({
                    text: "Reminders Enabled",
                    severity: "success",
                    open: true,
                  });
                });
            } else {
              setAlert({
                text: "Reminders Enabled on this Device",
                severity: "success",
                open: true,
              });
            }
          })
          .catch((error) => {
            console.error(error);
            setAlert({
              text: "Dang! An error occurred. Please try again later.",
              severity: "error",
              open: true,
            });
          });
        setLoading(false);
      } else if (operation === "revoke") {
        setLoading(true);
        let deviceToken = "";
        if (localStorage.getItem("deviceToken") !== undefined) {
          deviceToken = localStorage.getItem("deviceToken");
        }
        // const deviceToken = await messaging.getToken({vapidKey: publicVapidKey});
        const oldDeviceArray = userData.messaging.device_ids;
        firebase
          .messaging()
          .deleteToken()
          .then(async () => {
            localStorage.setItem("deviceToken", "");
            const newDeviceArray = oldDeviceArray.filter((device) => {
              return device.device_id !== deviceToken;
            });
            await db.collection("users").doc(`${currentUser.uid}`).update({
              "messaging.device_ids": newDeviceArray,
            });
            setAlert({
              text: "Reminders Disabled",
              severity: "success",
              open: true,
            });
          })
          .catch((error) => {
            console.log(error);
            setAlert({
              text: "Dang! An error occurred. Please try again later.",
              severity: "error",
              open: true,
            });
          });
        setLoading(false);
      } else {
        console.error("Unhandled Response Received.");
        setAlert({
          text: "Dang! An error occurred. Please try again later.",
          severity: "error",
          open: true,
        });
      }
    } else {
      console.log("localStorage Unavailable");
      setAlert({
        text:
          "Dang! It appears that your browser, or settings, don't support reminders. Please try another browser such as a recent version of Chrome, Firefox or Edge.",
        severity: "error",
        open: true,
      });
    }
  };

  const tabNameToIndex = {
    0: "reminders",
    1: "lesson",
    2: "account",
    3: "subscription",
  };

  const indexToTabName = {
    reminders: 0,
    lesson: 1,
    account: 2,
    subscription: 3,
  };

  const [selectedTab, setSelectedTab] = useState(indexToTabName[tab]);

  const handleChange = (event, newValue) => {
    history.push(`/settings/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
  };

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      if (selectedTab < 3) {
        const newValue = selectedTab + 1;
        handleChange(eventData, newValue);
      }
    },
    onSwipedRight: (eventData) => {
      if (selectedTab > 0) {
        const newValue = selectedTab - 1;
        handleChange(eventData, newValue);
      }
    },
  });

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const closeAlert = () => {
    setAlert({ text: "", severity: "", open: false });
  };

  if (userData === null || loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress
          size={200}
          thickness={1.5}
          className={classes.CircularProgress}
        />
      </div>
    );
  }

  if (
    userData.subscription.status === "canceled" ||
    userData.subscription.status === "unpaid" ||
    userData.subscription.status === "incomplete_expired"
  ) {
    return (
      <>
        <main className={classes.main} {...handlers}>
          <Paper className={classes.paper}>
            <AppBar position="static">
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                aria-label="simple tabs lesson"
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab
                  label="Reminders"
                  {...a11yProps(0)}
                  data-cy="reminderTab"
                />
                <Tab label="Lesson" {...a11yProps(1)} data-cy="lessonTab" />
                <Tab label="Account" {...a11yProps(2)} data-cy="accountTab" />
                <Tab
                  label="Subscription"
                  {...a11yProps(3)}
                  data-cy="subscriptionTab"
                />
              </Tabs>
            </AppBar>
            {selectedTab === 0 && (
              <Fragment>
                <Typography
                  variant="h6"
                  className={classes.headers}
                  data-cy="header"
                >
                  Permissions
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.bodyText}
                  data-cy="body1"
                >
                  Ummm.... unfortunately the reminder functionality requires a
                  valid subscription which we seem to have difficultly locating
                  in your account.
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.bodyText}
                  data-cy="body2"
                >
                  If you would like to continue using Badass ACIM then please go
                  to the subscription tab.
                </Typography>
              </Fragment>
            )}
            {selectedTab === 1 && (
              <Fragment>
                <Typography
                  variant="h6"
                  className={classes.headers}
                  data-cy="header"
                >
                  Lesson Settings
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.bodyText}
                  data-cy="body1"
                >
                  Ummm.... unfortunately this functionality requires a valid
                  subscription which we seem to have difficultly locating in
                  your account.
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.bodyText}
                  data-cy="body2"
                >
                  If you would like to continue using Badass ACIM then please go
                  to the subscription tab.
                </Typography>
              </Fragment>
            )}
            {selectedTab === 2 && <UserSettings userData={userData} />}
            {selectedTab === 3 && <Subscribe userData={userData} />}
            <Snackbar
              open={alert.open}
              autoHideDuration={6000}
              onClose={closeAlert}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MuiAlert
                data-cy="settingsAlert"
                severity={alert.severity}
                variant="filled"
                onClose={closeAlert}
              >
                {alert.text}
              </MuiAlert>
            </Snackbar>
          </Paper>
        </main>
      </>
    );
  }

  return (
    <>
      <main className={classes.main} {...handlers}>
        <Paper className={classes.paper}>
          <AppBar position="static">
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="simple tabs lesson"
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab label="Reminders" {...a11yProps(0)} data-cy="reminderTab" />
              <Tab label="Lesson" {...a11yProps(1)} data-cy="lessonTab" />
              <Tab label="Account" {...a11yProps(2)} data-cy="accountTab" />
              <Tab
                label="Subscription"
                {...a11yProps(3)}
                data-cy="subscriptionTab"
              />
            </Tabs>
          </AppBar>
          {selectedTab === 0 && (
            <Fragment>
              <Typography
                variant="h6"
                className={classes.headers}
                data-cy="header"
              >
                Permissions
              </Typography>
              {checkNotifyPermission()}
              <Typography variant="h6" className={classes.headers}>
                Automagic Reminders
              </Typography>
              {autoMagicText()}
            </Fragment>
          )}
          {selectedTab === 1 && <LessonSettings userData={userData} />}
          {selectedTab === 2 && <UserSettings userData={userData} />}
          {selectedTab === 3 && <Subscribe userData={userData} />}
          <Snackbar
            open={alert.open}
            autoHideDuration={6000}
            onClose={closeAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              data-cy="settingsAlert"
              severity={alert.severity}
              variant="filled"
              onClose={closeAlert}
            >
              {alert.text}
            </MuiAlert>
          </Snackbar>
        </Paper>
      </main>
    </>
  );
}

const styles = (theme) => ({
  main: {
    width: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  CircularProgress: {
    marginTop: "15%",
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  submit: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      width: "30%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "50%",
    },
  },
  headers: {
    marginTop: theme.spacing(3),
  },
  container: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
  },
  bodyText: {
    marginTop: theme.spacing(1),
  },
});

export default withRouter(withStyles(styles)(Settings));
