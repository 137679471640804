import React, { useContext, useState } from "react";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Grid,
  Snackbar,
  Typography,
  withStyles,
  TextField,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { AuthContext } from "../../providers/Auth";
import firebase from "../../config/firebase";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import axios from "axios";

function SupportContact(props) {
  const { currentUser } = useContext(AuthContext);
  const { classes } = props;
  const [supportDetails, setSupportDetails] = useState({
    name: currentUser.displayName,
    email: currentUser.email,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ text: "", severity: "", open: false });

  const handleFormChange = (event) => {
    setSupportDetails({
      ...supportDetails,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(supportDetails);

    if (
      supportDetails.name === "" ||
      supportDetails.email === "" ||
      supportDetails.message === "" ||
      supportDetails.type === ""
    ) {
      setAlert({
        text: "All fields are required.",
        severity: "error",
        open: true,
      });
      setLoading(false);
    } else {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          axios({
            method: "post",
            url:
              "https://us-central1-badass-acim.cloudfunctions.net/api/support/create-ticket",
            headers: {
              accepts: "application/json",
              authorization: idToken,
            },
            data: supportDetails,
          })
            .then((response) => {
              setAlert({
                text:
                  "Thank you! Your message has been received and will be responded to as soon as possible. You will also receive an email with the ticket number so you can track this request.",
                severity: "success",
                open: true,
              });
              setSupportDetails({
                name: currentUser.displayName,
                email: currentUser.email,
                message: "",
                type: "",
              });
              setLoading(false);
            })
            .catch((error) => {
              setAlert({
                text: error.response.data.msg,
                severity: "error",
                open: true,
              });
              setLoading(false);
            });
        });

      // const supportRequest = firebase
      //   .functions()
      //   .httpsCallable("supportRequest");
      // supportRequest(supportDetails)
      //   .then((result) => {
      //     setAlert({
      //       text:
      //         "Thank you! Your message has been received and will be responded to as soon as possible. You will also receive an email with the ticket number so you can track this request.",
      //       severity: "success",
      //       open: true,
      //     });
      //     setSupportDetails({
      //       name: currentUser.displayName,
      //       email: currentUser.email,
      //       message: "",
      //       type: "",
      //     });
      //     setLoading(false);
      //   })
      //   .catch((error) => {
      //     setAlert({ text: error.message, severity: "error", open: true });
      //     setLoading(false);
      //   });
    }
  };

  const closeAlert = () => {
    setAlert({ text: "", severity: "", open: false });
  };

  return (
    <main className={classes.main}>
      <form className={classes.form}>
        <Grid container>
          <Grid item xs={1} md={2} lg={3}></Grid>
          <Grid item xs={10} md={8} lg={6}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="name">Name</InputLabel>
              <Input
                id="name"
                name="name"
                autoComplete="off"
                autoFocus
                value={supportDetails.name}
                onChange={(e) => handleFormChange(e)}
                required
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                type="email"
                id="email"
                name="email"
                autoComplete="off"
                value={supportDetails.email}
                onChange={(e) => handleFormChange(e)}
                required
              />
            </FormControl>
            <Typography variant="h6" className={classes.formHeaders}>
              What did you need assistance with? *
            </Typography>
            <RadioGroup
              row
              aria-label="support type"
              name="type"
              className={classes.radioButtons}
              value={supportDetails.type}
            >
              <FormControlLabel
                value="bug"
                control={<Radio color="primary" />}
                label="Bug/Support"
                labelPlacement="bottom"
                name="type"
                onChange={(e) => handleFormChange(e)}
                data-cy="bugRadio"
              />
              <FormControlLabel
                value="feature"
                control={<Radio color="primary" />}
                label="Feature Request"
                labelPlacement="bottom"
                name="type"
                onChange={(e) => handleFormChange(e)}
              />
              <FormControlLabel
                value="other"
                control={<Radio color="primary" />}
                label="Other"
                labelPlacement="bottom"
                name="type"
                onChange={(e) => handleFormChange(e)}
              />
            </RadioGroup>
            <Typography variant="h6" className={classes.formHeaders}>
              Please describe the issue, in detail. *
            </Typography>
            <FormControl margin="normal" required fullWidth>
              {/*<InputLabel htmlFor="message">Message</InputLabel>*/}
              <TextField
                id="message"
                name="message"
                multiline
                label="Issue Description"
                placeholder="Issue description"
                rowsMax={15}
                value={supportDetails.message}
                onChange={(e) => handleFormChange(e)}
                required
                data-cy="messageField"
              ></TextField>
            </FormControl>
            <Button
              type="button"
              data-cy="submit-button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={onSubmit}
              className={classes.submit}
              disabled={loading}
            >
              {loading ? "Please wait..." : "Send Support Request"}
            </Button>
          </Grid>
          <Grid item xs={1} md={2} lg={3}></Grid>
        </Grid>
      </form>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          data-cy="settingsAlert"
          severity={alert.severity}
          variant="filled"
          onClose={closeAlert}
        >
          {alert.text}
        </MuiAlert>
      </Snackbar>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "100%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  bodyText: {
    "& p:not(:first-child)": {
      margin: "16px 0px",
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  radioButtons: {
    justifyContent: "center",
  },
  formHeaders: {
    align: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontSize: "small",
  },
  descriptiveText: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
    fontSize: "xx-small",
  },
  form: {
    marginBottom: theme.spacing(10),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

export default withStyles(styles)(SupportContact);
